<template>
  <div class="container">
    <div v-if="isLoading" class="loading-overlay">
      <vue3-lottie ref="lottie"
                   :animationData="animationData"
                   height="60vh"
                   width="100%"
      />
    </div>
    <div class="row">
      <h6 class="text-secondary mb-2">App Connections</h6>

      <!-- Displaying app connections -->
      <div class="row mb-5" v-if="appKeys.length > 0">
        <div class="col-md-3" v-for="appKey in appKeys" :key="appKey">
          <div class="card app-item shadow-sm app-card">
            <img :src="getAppDetails[appKey]?.image" :alt="getAppDetails[appKey]?.name" class="card-img-top app-icon"/>
            <div class="card-body">
              <h5 class="card-title text-center">{{ getAppDetails[appKey]?.name }}</h5>
              <div v-if="apps[appKey]?.actions">
                <div v-for="(action, actionKey) in apps[appKey].actions" :key="actionKey">
                  <button @click="performAction(appKey, actionKey)" class="btn btn-primary w-100 m-0 mb-2">
                    {{ action.name }}
                  </button>
                </div>
              </div>

            </div>
            <div class="d-flex justify-content-around">
              <button @click="editApp(appKey)" class="btn btn-secondary w-100 m-0 m-0">
                Edit
              </button>
              <button @click="deleteApp(appKey)" class="btn btn-danger w-100 m-0">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Message when no app connections found -->
      <div v-else>
        <div class="row d-flex justify-content-center align-items-center mb-3 alert alert-warning p-0">
          <div class="col-12 col-md-6">
            <div class="alert-icon">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <img
                class="w-75 me-3 mb-0"
                src="@/assets/img/illustrations/link-dynamic-gradient.png"
                alt="logo"
            />
          </div>
          <div class="col-12 col-md-6">
            <h1 style="color: white">App Connections</h1>

            <h2 style="color: white">You have no Connections configured</h2>
            <p>
              please finish creating the Expert and/or visit the Apps section
            </p>

          </div>
          <div class="d-flex justify-content-around p-0 d-none">
            <button class="btn btn-primary w-100 m-0" @click="toNext()">
              Next Step
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit App Modal -->
    <VueFinalModal
        v-model="showEditModal"
        classes="modal-container"
        class="modal-container overflow-scroll modal-fullscreen"
        content-class="modal-content"
        :hide-overlay="true"
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
    >
      <div v-if="editingApp" class="mt-4 mb-4 card p-3">
        <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top p-3">
          <h5 class="modal-title text-white">Settings for {{ getAppDetails[editingApp]?.name }}</h5>

          <button type="button" class="close-btn btn text-white" @click="closeEditModal">
            <i class="material-icons-round opacity-10 fs-5">close</i>
          </button>
        </div>
        <DynamicForm
            v-if="editingApp !== 'openai_assistant' && expertApps[editingApp].tool_type !== 'openai_asst'"
            :showExpertChoose="false"
            :settings="getAppSettings()"
            :descriptions="getAppDescriptions()"
            :validations="getAppValidations()"
            @update="updateFormData"
            @form-valid="handleFormValid"
        />
        <OpenAIAssistant
            v-if="expertApps[editingApp].tool_type === 'openai_asst'"
            :showExpertChoose="false"
            :settings="getAppSettings()"
            @close="closeEditModal"
            @update="updateFormData"
            @form-valid="handleFormValid"
        />

        <button @click="saveAppChanges" :disabled="!isFormValid" class="btn btn-success mt-2">Save Settings</button>
      </div>
    </VueFinalModal>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import tavilyIcon from '@/assets/icons/tavily.png';
import wolframIcon from '@/assets/icons/wolfram.png';
import documentRetrieverIcon from '@/assets/icons/document_retriever.png';
import notionIcon from '@/assets/icons/notion.png';
import upstashIcon from '@/assets/icons/upstash-redis-private.png';
import loadingChat from "@/assets/img/illustrations/loadingChat.json";
import DynamicForm from "@/views/components/DynamicForm.vue";
import OpenAIAssistant from "@/views/components/OpenAIAssistant.vue";
import {VueFinalModal} from "vue-final-modal";
const { VUE_APP_DATACENTER_URL } = process.env;


export default {
  components: {
    DynamicForm,
    OpenAIAssistant,
    VueFinalModal
  },
  data() {
    return {
      isLoading: false,
      animationData: loadingChat,
      showEditModal: false,
      editingApp: null,
      editedAppData: {},
      isFormValid: false,
      apps: [
        {
          id: 1,
          identifier: 'upstash_redis_public',
          category: "database",
          name: 'Upstash Redis Public',
          description: 'The Public Vector Database for B-Bot',
          logo: 'https://upstash.com/logo/upstash-white-bg.svg',
          connected: false,
          database: true,
          database_type: 'upstash',
          connectable: false,
          href: 'https://upstash.com',
          settings: {
            private: true,
            url: 'https://api.upstash.com',
            apiKey: '',
          },
          descriptions: {
            private: '',
            url: 'API URL for Upstash',
            apiKey: 'API Key for Upstash',
          },
          validations: {
          },
        },
        {
          id: 2,
          identifier: 'upstash_redis_private',
          category: "database",
          name: 'Own Upstash Redis',
          description: 'Choose your own Upstash Redis Database',
          logo: 'https://upstash.com/logo/upstash-white-bg.svg',
          connected: false,
          connectable: true,
          database: true,
          database_type: 'upstash',
          href: 'https://upstash.com',
          settings: {
            private: true,
            redis_rest_url: 'https://something-44230.upstash.io',
            redis_rest_token: 'GAAIncDE2ODFiMDA0Mjc0NmU0NmIzOTU3MDcyZmNl',
            vector_url: 'https://api.upstash.com',
            vector_token: 'GAAIncDE'
          },
          descriptions: {
            private: '',
            redis_rest_url: 'Upstash Redis Rest URL, can be found in the dashboard',
            redis_rest_token: 'Upstash Redis Rest TOKEN, can be found in the dashboard',
            vector_url: 'Upstash Vector URL, can be found in the dashboard',
            vector_token: 'Upstash Vector TOKEN, can be found in the dashboard',
          },
          validations: {
            redis_rest_url: {
              required: true,
              type: 'string',
              pattern: 'https://.*',
              custom: (value) => {
                if (!value.startsWith('https')) {
                  return 'URL must start with https';
                }
                return true;
              }
            },
            vector_url: {
              required: true,
              type: 'string',
              pattern: 'https://.*',
              custom: (value) => {
                if (!value.startsWith('https')) {
                  return 'URL must start with https';
                }
                return true;
              }
            },
            vector_token: {
              required: true,
              type: 'string',
              minLength: 32,
              maxLength: 64,
              custom: (value) => {
                if (!/^[a-zA-Z0-9]+$/.test(value)) {
                  return 'API Key must be alphanumeric';
                }
                return true;
              }
            },
            redis_rest_token: {
              required: true,
              type: 'string',
              minLength: 32,
              maxLength: 64,
              custom: (value) => {
                if (!/^[a-zA-Z0-9]+$/.test(value)) {
                  return 'API Key must be alphanumeric';
                }
                return true;
              }
            },
          },
        },
        {
          id: 3,
          identifier: 'redis_private',
          category: "database",
          name: 'Own Redis',
          description: 'Choose your own Redis Database',
          logo: 'https://upstash.com/logo/upstash-white-bg.svg',
          connected: false,
          connectable: true,
          database: true,
          database_type: 'redis',
          href: 'https://upstash.io',
          settings: {
            private: true,
            redis_rest_url: 'redis://default:AazGU0NmIzOTU3MDcyZmNlOWVhZTdkZnAxNDQyMzA@example-koala-44230.redis.io:6379',
          },
          descriptions: {
            private: '',
            redis_rest_url: 'Redis URL of your own private Redis Database',
          },
          validations: {
            redis_rest_url: {
              required: true,
              type: 'string',
              pattern: 'redis://.*',
              custom: (value) => {
                if (!value.startsWith('redis://')) {
                  return 'URL must start with redis://';
                }
                return true;
              }
            },
          },
        },
        {
          id: 4,
          identifier: 'notion_connector',
          category: "Connector",
          name: 'Notion Connector',
          description: 'Synchronize your database with B-Bot.',
          logo: 'https://cdn.freelogovectors.net/wp-content/uploads/2023/09/notionlogo-freelogovectors.net_.png',
          connected: false,
          connectable: true,
          href: 'https://upstash.com',
          settings: {
            private: true,
            db: '',
            secret: '',
          },
          descriptions: {
            private: '',
            db: 'Database ID of Notion Page of Folder',
            secret: 'Secret key for the Notion API (you must create our own App in the Notion App)',
          },
        },
        {
          id: 5,
          identifier: 'tavily_search',
          category: "search",
          name: 'Tavily Search',
          description: 'GPT Researcher that researches the Internet for you',
          logo: 'https://docs.tavily.com/img/tavily.png',
          connected: false,
          connectable: false,
          href: 'https://upstash.com',
          settings: {
            private: true,
            url: 'https://api.upstash.com',
            apiKey: '',
          },
          descriptions: {
            private: '',
            url: 'Url for Tavily Search API',
            apiKey: 'API Key for Tavily Search API',
          },
          validations: {
            url: {
              required: true,
              type: 'string',
              pattern: 'https://.*',
              custom: (value) => {
                if (!value.startsWith('https')) {
                  return 'URL must start with https';
                }
                return true;
              }
            }
          },
        },
        {
          id: 6,
          identifier: 'wolfram_alpha',
          category: "calculate",
          name: 'Wolfram Alpha',
          description: 'Calculates and answers scientific questions.',
          logo: 'https://w7.pngwing.com/pngs/767/137/png-transparent-wolfram-alpha-logo-search-engines.png',
          connected: false,
          connectable: false,
          href: 'https://upstash.com',
        },
        {
          id: 7,
          identifier: 'openapi_hubspot_companies',
          category: "crm",
          name: 'Hubspot Connection Companies',
          description: 'Creates Connection to Hubspot trough API KEY.',
          logo: 'https://1000logos.net/wp-content/uploads/2022/12/HubSpot-Logo.png',
          connected: false,
          connectable: true,
          href: 'https://hubspot.com',
          settings: {
            private: true,
            tool_type: 'openapi',
            name: 'Hubspot Connection Companies',
            description_addition: 'You can ask this tool about Companies in the CRM',
            openapi_yml_url: 'https://api.hubspot.com/api-catalog-public/v1/apis/crm/v3/objects/companies',
            allow_dangerous_operations: false,
            api_key: 'pat-eu1-711b6711-268e-42b4-91c6-8f878e33d1a2',
            api_key_type: 'bearer_no_access_fetching_yml'
          },
          descriptions: {
            private: '',
            api_key: 'API Key for Hubspot API',
            allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
          },
          validations: {
            api_key: {
              required: true,
            }
          },
        },
        {
          id: 8,
          identifier: 'openapi_hubspot_contacts',
          category: "crm",
          name: 'Hubspot Connection Contacts',
          description: 'Creates Connection to Hubspot trough API KEY.',
          logo: 'https://1000logos.net/wp-content/uploads/2022/12/HubSpot-Logo.png',
          connected: false,
          connectable: true,
          href: 'https://hubspot.com',
          settings: {
            private: true,
            tool_type: 'openapi',
            name: 'Hubspot Connection Contacts',
            description_addition: 'You can ask this tool about Contacts in the CRM',
            openapi_yml_url: 'https://api.hubspot.com/api-catalog-public/v1/apis/crm/v3/objects/contacts',
            allow_dangerous_operations: false,
            api_key: 'pat-eu1-711b6711-268e-42b4-91c6-8f878e33d1a2',
            api_key_type: 'bearer_no_access_fetching_yml'
          },
          descriptions: {
            private: '',
            api_key: 'API Key for Hubspot API',
            allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
          },
          validations: {
            api_key: {
              required: true,
            }
          },
        },
        {
          id: 9,
          identifier: 'openapi_hubspot_products',
          category: "crm",
          name: 'Hubspot Connection Products',
          description: 'Creates Connection to Hubspot trough API KEY.',
          logo: 'https://1000logos.net/wp-content/uploads/2022/12/HubSpot-Logo.png',
          connected: false,
          connectable: true,
          href: 'https://hubspot.com',
          settings: {
            private: true,
            tool_type: 'openapi',
            name: 'Hubspot Connection Products',
            description_addition: 'You can ask this tool about Products in the CRM',
            openapi_yml_url: 'https://api.hubspot.com/api-catalog-public/v1/apis/crm/v3/objects/products',
            allow_dangerous_operations: false,
            api_key: 'pat-eu1-711b6711-268e-42b4-91c6-8f878e33d1a2',
            api_key_type: 'bearer_no_access_fetching_yml'
          },
          descriptions: {
            private: '',
            api_key: 'API Key for Hubspot API',
            allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
          },
          validations: {
            api_key: {
              required: true,
            }
          },
        },
        {
          id: 10,
          identifier: 'spoonacular',
          category: "food",
          name: 'Spoonacular',
          description: 'The spoonacular Nutrition, Recipe, and Food API allows you to access over thousands of recipes, thousands of ingredients, 800,000 food products, over 100,000 menu items, and restaurants.',
          logo: 'https://spoonacular.com/application/frontend/images/logo-simple-framed-green-gradient.svg',
          connected: false,
          connectable: true,
          href: 'https://hubspot.com',
          settings: {
            private: true,
            tool_type: 'openapi',
            name: 'Spoonacular',
            description_addition: 'The spoonacular Nutrition, Recipe, and Food API allows you to access over thousands of recipes, thousands of ingredients, 800,000 food products, over 100,000 menu items, and restaurants.',
            openapi_yml_url: 'https://spoonacular.com/application/frontend/images/logo-simple-framed-green-gradient.svg',
            allow_dangerous_operations: false,
            api_key: 'a100d942744541f7a28b521e0e75e524',
            api_key_type: 'x-api-key'
          },
          descriptions: {
            private: '',
            api_key: 'API Key for Spoonacular',
            allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
          },
          validations: {
            api_key: {
              required: true,
            }
          },
        },
        {
          id: 11,
          identifier: 'zefix',
          category: "search",
          name: 'Zefix',
          description: '"Zentraler Firmen Index" of Switzerland',
          logo: 'https://www.zefix.ch/assets/images/logo.svg',
          connected: false,
          connectable: true,
          href: 'https://zefix.ch',
          settings: {
            private: true,
            tool_type: 'openapi',
            name: 'Spoonacular',
            description_addition: 'If you need information about companies in Switzerland',
            openapi_yml_url: 'https://www.zefix.admin.ch/ZefixPublicREST/v3/api-docs',
            allow_dangerous_operations: false,
            api_key: 'a100d942744541f7a28b521e0e75e524',
            api_key_type: 'basic'
          },
          descriptions: {
            private: '',
            api_key: 'API Key for Zefix, encode your email:password in Base64 an pass it in here',
            allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
          },
          validations: {
            api_key: {
              required: true,
            }
          },
        },
        {
          id: 12,
          identifier: 'shopware_storefront',
          category: "ecommerce",
          name: 'Shopware Storefront',
          description: 'The Storefront App for retrieving Product Data in Shopware',
          logo: 'https://www.eevolution.de/wp-content/uploads/2023/08/shopware_logo_blue-1.webp',
          connected: false,
          connectable: true,
          href: 'https://showpare.com',
          settings: {
            private: true,
            tool_type: 'openapi',
            name: 'Shopware',
            description_addition: 'If you need information about Products in a Shopware Store.',
            openapi_yml_url: '/store-api/_info/openapi3.json',
            domain: 'https://beyond-livin.ch',
            api_key: 'SWSCVEXTDMFKRE5ENU9TV0GZDQ',
            api_key_type: 'sw-access-key',
            allow_dangerous_operations: false
          },
          descriptions: {
            private: '',
            api_key: 'API Key for Shopware',
            allow_dangerous_operations: ' Wether to allow Creation or Editing in The Plugin',
          },
          validations: {
            api_key: {
              required: true,
            }
          },
        },
        {
          id: 13,
          identifier: 'openai_assistant',
          category: "assistants",
          name: 'OpenAI Assistant',
          description: 'An Assistant for you B-Bot to help you with your tasks',
          logo: 'https://media.licdn.com/dms/image/D5612AQEzwj7EvpcXHQ/article-cover_image-shrink_720_1280/0/1700332696859?e=2147483647&v=beta&t=O_m9znUFQLgn06PXf7Ck6UgXNcztGq5-CpDwHTdgzLU',
          connected: false,
          connectable: true,
          href: 'https://platform.openai.com/docs/assistants/overview',
          settings: {
            private: true,
            tool_type: 'openai_asst',
            name: 'Name of the Assistant',
            description: 'Description of the Assistant',
            assistant_id: 'asst_1234567890',
            apiKey: 'sk-1234567890',
          },
          descriptions: {
            private: '',
            tool_type: 'API URL for Upstash',
            name: 'Name of the Assistant tool, e.g. Law Assistant (This is the Tool name B-Bot sees)',
            description: 'Description of the Assistant tool, e.g. Assists in Law questions knows everything about law. (This is the Tool description B-Bot sees)',
            assistant_id: 'The Assistant ID for OpenAI Assistant',
            apiKey: 'API Key of the OpenAI',
          },
        },
      ],
    };
  },
  props: {
    expertApps: {
      type: [String, Object], // Allow expertApps to be either a String or Object
      required: true
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(['apps', 'selectedExpert', 'user']),
    parsedApps() {
      if (typeof this.expertApps === 'string') {
        try {
          return JSON.parse(this.expertApps) || {}; // Fallback to an empty object if parsing returns null
        } catch (error) {
          console.error('Error parsing expertApps:', error);
          return {}; // Return an empty object if parsing fails
        }
      } else if (this.expertApps && typeof this.expertApps === 'object') {
        // If expertApps is already an object (or object-like structure), return it directly
        return this.expertApps;
      } else {
        return {}; // Return an empty object if expertApps is undefined or null
      }
    },
    appKeys() {
      return Object.keys(this.parsedApps);
    },
    appImages() {
      return {
        tavily_search: tavilyIcon,
        wolfram_alpha: wolframIcon,
        document_retriever: documentRetrieverIcon,
        notion_connector: notionIcon,
        upstash_redis_private: upstashIcon,
      };
    },
    getAppDetails() {
      // Map expertApps to app names and images
      const appDetails = {};
      for (const [key, value] of Object.entries(this.expertApps)) {
        appDetails[key] = {
          name: this.apps[key]?.name || value.name,
          image: this.appImages[key] || require('@/assets/img/illustrations/link-dynamic-gradient.png') // Provide a default image
        };
      }
      return appDetails;
    },


  },
  methods: {
    ...mapActions(['callApiFunction', 'fetchExperts']),
    getAppProperty(property) {
      if (typeof property !== 'string') {
        console.error('Expected property to be a string, got:', typeof property);
        return {};
      }

      // Find the corresponding expert app details
      const expertAppData = this.expertApps[this.editingApp] || null;
      if (!expertAppData) {
        console.warn('No expert app data found for editingApp:', this.editingApp);
        return {};
      }

      expertAppData.id = this.editingApp;

      // Special handling for specific tool types
      if (expertAppData.tool_type === "openai_asst") {
        expertAppData.id = "openai_assistant";
      }

      // Log the expert app data for verification

      // Find the matching app data from `this.apps` based on identifier
      const appData = Object.values(this.apps).find(app => app.identifier === expertAppData.id) || null;

      // Log the found app data for debugging

      // If appData exists, merge expertAppData into appData's settings
      let result;
      if (appData) {
        if (property === 'settings') {
          // Merge expertAppData into appData settings
          result = { ...appData.settings, ...expertAppData };
        } else {
          // For other properties, use appData[property] and fallback to expertAppData
          result = { ...appData[property], ...expertAppData[property] };
        }
      } else {
        // If no appData, return expertAppData's property as fallback
        result = { ...expertAppData[property] };
      }

      // Log the merged or overwritten property value

      return result || {};
    },

    getAppSettings() {
      return this.getAppProperty('settings');
    },

    getAppDescriptions() {
      return this.getAppProperty('descriptions');
    },

    getAppValidations() {
      return this.getAppProperty('validations');
    },
    editApp(appKey) {
      this.editingApp = appKey;
      this.editedAppData = { ...this.parsedApps[appKey] };
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.editingApp = null;
      this.editedAppData = {};
    },
    async saveAppChanges() {
      if (this.editingApp && this.isFormValid) {
        try {
          await this.handleSubmit(this.editingApp, this.editedAppData);
          //this.$emit('app-updated', this.editingApp, this.editedAppData);
          this.closeEditModal();
        } catch (error) {
          console.error('Failed to update app:', error);
          // Handle error (e.g., show error message to user)
        }
      }
    },
    getCookie(name) {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    async handleSubmit(key, payload) {
      const url = this.selectedExpert.id
          ? `${VUE_APP_DATACENTER_URL}/api/experts/${this.selectedExpert.id}` // PUT to update existing expert
          : `${VUE_APP_DATACENTER_URL}/api/experts`; // POST to create new expert

      const method = this.selectedExpert.id ? 'PUT' : 'POST';

      const accessToken = this.getCookie('datacenterAccessToken');

      try {
        const appIdentifier = key;
        const currentSettings = this.selectedExpert.attributes.apps || {};
        const updatedSettings = {
          ...currentSettings,
          [appIdentifier]: payload,
        };
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            data: {
              apps: updatedSettings,
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to ${this.selectedExpert.id ? 'update' : 'create'} expert: ${response.statusText}`);
        }

        await response.json();

        setTimeout(() => {
          this.fetchExperts();
        }, 1000);
        this.showModal = false;

      } catch (error) {
        console.error('Error submitting expert data:', error);
        // Handle error, e.g., showing error message to the user
      }
      this.showModal = false;
    },
    updateFormData(newFormData) {
      this.editedAppData = newFormData;
    },
    handleFormValid(isValid) {
      this.isFormValid = isValid;
    },
    deleteApp(appKey) {
      this.$emit('delete-app', appKey);
    },
    toNext() {
      this.$emit('to-next')
    },
    performAction(appKey, action) {
      this.isLoading = true;

      switch(appKey) {
        case 'notion_connector':
          if (action === 'sync') {
            this.syncNotionConnector();
          }
          break;
          // Add more cases for other apps and their respective actions
        default:
          console.log(`Action ${action} for app ${appKey} is not defined.`);
      }
    },
    async syncNotionConnector() {

      const payload = {
        id: this.selectedExpert.id.toString(), // Replace with actual user ID logic
        apps: this.selectedExpert.attributes.apps,
        private: true
      };


      try {
        await this.callApiFunction({ functionName: 'connect_notion', payload });
        // Handle the response as needed
      } catch (error) {
        console.error('Failed to sync Notion Connector:', error);
      }
      this.isLoading = false;

    }
  }
};
</script>

<style scoped>


.app-item {
  margin-bottom: 20px;
}
.app-icon {
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 20px;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}

.app-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* This makes sure the card takes up the full height */
}

.card-body {
  flex-grow: 1;
}

.card-text {
  flex-grow: 1;
}

.card img {
  max-height: 150px;
  object-fit: contain;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.btn {
  margin-top: 10px;
}

</style>
