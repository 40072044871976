<template>
  <navbar btnBackground="bg-gradient-success" />
  <div
    class="page-header align-items-start min-vh-100"
    style="
      background-image: url('https://cdn.shopify.com/s/files/1/0795/1653/3018/files/Your_B-Bot_Me.png?v=1704915784');
    "
  >
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-primary shadow-success border-radius-lg py-3 pe-1"
              >
                <h4 class="text-white font-weight-bolder text-center mt-2 mb-0">
                  Log in
                </h4>
                <p class="w-100 text-white text-center font-weight-bolder text-center mt-2 mb-0">
                  Get into the Magical world of B-Bot!
                </p>
                <div class="row mt-3 d-none">
                  <div class="col-2 text-center ms-auto">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fab fa-facebook text-white text-lg"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center px-1">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fab fa-github text-white text-lg"></i>
                    </a>
                  </div>
                  <div class="col-2 text-center me-auto">
                    <a class="btn btn-link px-3" href="javascript:;">
                      <i class="fab fa-google text-white text-lg"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <material-button
                  @click="loginWithAuth0"
                  class="my-4 mb-2"
                  variant="gradient"
                  color="primary"
                  fullWidth
              >
                Login
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 col-md-6 my-auto">
            <div class="copyright text-center text-sm text-white text-lg-start">
              © {{ new Date().getFullYear() }}, made with
              <i class="fa fa-heart" aria-hidden="true"></i> by
              <a
                href="https://b-bot.ch/team"
                class="font-weight-bold text-white"
                target="_blank"
                >B-Bot Team</a
              >
              for Sustainable AI.
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ul
              class="nav nav-footer justify-content-center justify-content-lg-end"
            >
              <li class="nav-item">
                <a
                  href="https://b-bot.ch"
                  class="nav-link text-white"
                  target="_blank"
                  >Shop</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://b-bot.ch/about"
                  class="nav-link text-white"
                  target="_blank"
                  >About Us</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://b-bot.ch/blog"
                  class="nav-link text-white"
                  target="_blank"
                  >Blog</a
                >
              </li>
              <li class="nav-item">
                <a
                  href="https://b-bot.ch/impressum"
                  class="nav-link pe-0 text-white"
                  target="_blank"
                  >Impressum</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import { mapMutations } from "vuex";
import {authService} from "@/auth/authService";

export default {
  name: "sign-in",
  components: {
    Navbar,
    MaterialButton,
  },
  beforeMount() {
    this.hideEveryDisplay();
    this.hideConfig();
  },
  beforeUnmount() {
    this.hideEveryDisplay();
    this.hideConfig();
    //this.toggleEveryDisplay();
    //this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["hideEveryDisplay", "hideConfig"]),
    async loginWithAuth0() {
      await authService.login();
    }
  },
  mounted() {
    this.hideEveryDisplay();
    this.hideConfig();
    this.$store.state.showNavbar = false; // Hide the navbar when the component is mounted
  }

};
</script>
