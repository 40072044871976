<template>
  <div class="container">
    <div class="d-flex justify-content-center align-items-center mb-3">
      <img
          class="w-25 me-3 mb-0 backdrop"
          src="@/assets/img/illustrations/gym-dynamic-gradient.png"
          alt="logo"
      />
      <h2>Train Experts</h2>
      <DocumentationLink
          popoverTitle="Train Experts"
          class="z-index-5 documentation-link"
          description="Learn more about the Training of an Expert"
          docLink="https://docs.b-bot.space/essentials/training"
      />
    </div>
    <div class="row">
      <div v-for="expert in experts" :key="expert.id" class="col-md-6 col-lg-4 mb-3">
        <div class="card shadow-sm" @click="handleExpertSelection(expert)" :class="{ active: expert.id === selectedExpert.id }">
          <div class="card-body text-center">
            <img :src="expert.attributes.profile_picture?.data?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'" class="rounded-circle mb-3" :alt="expert.attributes.name" style="width: 100px; height: 100px;" />
            <h5 class="card-title">{{ expert.attributes.name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ expert.attributes.profession }}</h6>
            <p class="card-text">{{ expert.attributes.function_name }}</p>
            <p class="card-text">{{ expert.attributes.function_description }}</p>
            <span class="badge bg-success">Online</span>
            <p class="card-text"><small class="text-muted">{{ formatDate(expert.attributes.createdAt) }}</small></p>
          </div>
          <div class="d-flex justify-content-around">
            <button class="btn btn-primary btn-link m-0 w-100" @click="handleExpertSelection(expert)">Train</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DocumentationLink from "./DocumentationLink.vue";

export default {
  name: 'ExpertListTraining',
  components: {
    DocumentationLink
  },
  computed: {
    ...mapState(['experts', 'selectedExpert']),
  },
  methods: {
    ...mapMutations(['setSelectedExpert', 'setTrainingOption']),
    handleExpertSelection(expert) {
      this.setSelectedExpert(expert); // Speichere den ausgewählten Experten im Vuex Store
      this.$emit('expert-selected', expert); // Emit das Event mit dem ausgewählten Experten
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>


<style scoped>
.expert-list {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Abstand zwischen den Listenelementen */
  list-style: none;
  overflow-y: auto;
  height: 90vh; /* Anpassen, basierend auf deinem Layout */
  padding: 0;
  margin: 0;
}

.expert-item {
  display: flex;
  align-items: center;
  gap: 10px; /* Abstand zwischen Avatar und Info */
  padding: 10px;
  border: 1px solid #ddd; /* Leichter Rahmen für die Trennung */
  border-radius: 8px; /* Abgerundete Ecken */
  background-color: #fff; /* Hintergrundfarbe der Karte */
  cursor: pointer;
  transition: background-color 0.2s; /* Sanfter Übergang für Hover-Effekt */
}

.expert-item.active,
.expert-item:hover {
  background-color: #f0f0f0; /* Hervorhebung bei Hover/Auswahl */
}

.expert-info {
  width: 100%;
  flex: 1; /* Erlaubt der Info, den verfügbaren Platz auszunutzen */
  text-align: center; /* Linksbündige Textausrichtung */
}

.expert-info h5,
.expert-info p {
  margin: 0; /* Entfernt Standard-Margins */
}

.list-group {
  padding-left: 0; /* Entfernt den linken Padding der Liste */
  list-style: none; /* Entfernt Listensymbole */
}

.list-group-item {
  display: flex; /* Ermöglicht Flexbox innerhalb von Fähigkeiten */
  justify-content: center; /* Zentriert den Button */
}

.list-group-item button {
  width: 100%; /* Button nimmt den verfügbaren Platz ein */
}

@media (max-width: 767px) {
  /* Anpassungen für sehr kleine Bildschirme, falls nötig */
}
.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-body {
  flex-grow: 1;
}

.d-flex {
  display: flex;
  align-items: stretch; /* Ensures that all items stretch to the same height */
}

.card img {
  height: 150px; /* Ensures images don't stretch the card */
  object-fit: contain;
}

.backdrop{
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

</style>
