<template>
  <div class="chat-window p-0 card d-flex flex-column">
    <ConversationsModal v-model="showConversationsModal" />
    <AbilitiesModal  v-model="showAbilitiesModal" />
    <ModelsModal v-model="showModelsModal" />
    <ChatSettingsModal v-model="showChatSettingsModal" />
    <ChatConnectorsModal v-model="showChatConnectorsModal"/>
    <AddFileModal v-model="showAddFileModal" />
    <div class="messages overflow-auto" ref="messagesContainer">

      <WelcomeMessage v-if="Object.keys(chatMessages).length === 0"/>

      <Messages :messages="chatMessages" />
      <div v-if="isLoading" class="d-flex justify-content-center align-items-center">
        <vue3-lottie ref="lottie"
                     :animationData="animationData"
                     height="30vh"
                     width="50%"
        />
      </div>
      <div v-if="isErrorInRequest" class="error-indicator p-5">
        <div class="row d-flex justify-content-center align-items-center mb-3 alert alert-danger p-0">
          <div class="col-12 p-5">
            <div class="alert-icon">
              <i class="fas fa-exclamation-triangle"></i>
            </div>
            <h1 style="color: white">Error</h1>
            <p>Error: There was a problem with the request. Please try again.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-input-container">
      <ChatInput
          @send-message="handleSendMessage"
          @toggle-conversations-modal="showConversationsModal = !showConversationsModal"
          @toggle-abilites-modal="showAbilitiesModal = !showAbilitiesModal"
          @toggle-models-modal="showModelsModal = !showModelsModal"
          @toggle-settings-modal="showChatSettingsModal = !showChatSettingsModal"
          @toggle-connectors-modal="showChatConnectorsModal = !showChatConnectorsModal"
          @toggle-add-file-modal="showAddFileModal = !showAddFileModal"
          @back-button="handleBackButton"
          @scroll-down="scrollToBottom"
          @start-new-chat="wipeChat"
      />
    </div>

  </div>
</template>

<script>
import WelcomeMessage from './WelcomeMessage.vue';
import Messages from './Messages.vue';
import ChatInput from './ChatInput.vue';
import { mapActions, mapState } from 'vuex';
import ConversationsModal from "@/views/components/ConversationsModal.vue";
import AbilitiesModal from "@/views/components/AbilitiesModal.vue";
import ModelsModal from "@/views/components/ModelsModal.vue";
import ChatSettingsModal from "@/views/components/ChatSettingsModal.vue";
import ChatConnectorsModal from "@/views/components/ChatConnectorsModal.vue";
import loadingChat from "@/assets/img/illustrations/loadingChat.json";
import AddFileModal from "./AddFileModal.vue";

export default {
  name: 'ChatWindow',
  components: {
    AddFileModal,
    ChatConnectorsModal,
    ChatSettingsModal,
    ModelsModal,
    AbilitiesModal,
    ConversationsModal,
    Messages,
    ChatInput,
    WelcomeMessage
  },

  data() {
    return {
      animationData: loadingChat,
      unsavedChanges: false,
      showConversationsModal: false,
      showAbilitiesModal: false,
      showModelsModal: false,
      showChatSettingsModal: false,
      showChatConnectorsModal: false,
      showAddFileModal: false,
    };
  },
  computed: {
    ...mapState(['chatMessages', 'isLoading', 'isErrorInRequest', 'user', 'selectedConversationId', 'currentChat']),
    loadingStatus() {
      console.log("isLoading status:", this.isLoading);
      return this.isLoading;
    }
  },
  methods: {
    ...mapActions(['createNewChat', 'listenToCurrentChat', 'sendMessage','fetchUserChats', 'fetchUserChatsWithMessages', 'saveMessage', 'saveChat', 'wipeChat', 'updateUnsavedChanges']),
    async startNewChat(chatname) {
      try {
        //await this.fetchUserChats()
        await this.createNewChat(chatname);
        this.scrollToBottom();
      } catch (error) {
        console.error("Failed to start new chat:", error);
        // Handle error (e.g., show an error message to the user)
      }
    },
    handleSendMessage(message) {

      this.sendMessage(message); // Send the entire message object
      this.updateUnsavedChanges(true);
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    handleBackButton(){
      this.$emit('press-back-button'); // Emitting an event to the parent

    },
    scrollToBottom() {
      //const container = document.querySelector('.messages'); // Target by class
      //if (container) {
       // container.scrollTop = container.scrollHeight - container.clientHeight;
      //}
    },
    retry() {
      this.$store.commit('setIsErrorInRequest', false);
      //this.$store.dispatch('retry');
    },
    async inviteUser() {
      const invitationData = {
        email: "info@beyond-livin.com",
        invitedBy: this.user.sub,
        chatTitle: "TestChat",
        chatId: this.currentChat
      };

      await this.$store.dispatch('sendInvitation', invitationData);
    }
  },
  async mounted() {
    await this.$nextTick(() => {
      this.scrollToBottom();
    });
    await this.fetchUserChatsWithMessages();
    await this.listenToCurrentChat();

  },
  beforeUnmount() {
    // Consider resetting unsavedChanges state if needed
    this.updateUnsavedChanges(false); // Clean up on component unmount
    if (this.unsubscribe) this.unsubscribe();
  }
};
</script>


<style scoped>
.chat-window {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 88vh;
  overflow: hidden;
}

.messages{
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.chat-input-container {
  position: fixed;
  bottom: 5px;
  width: 100%; /* Make it full width on smaller screens */
}

/* Media query for screens wider than 1200px */
@media (min-width: 1201px) {
  .chat-input-container {
    position: static;
    transform: none;
    left: auto;
    bottom: auto;
  }
}

.main-content {
  overflow: hidden !important;
}
</style>
