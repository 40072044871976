<template>
  <div>
    <div v-for="(value, key) in filteredSettings" :key="key" class="mb-3 form-group">
      <div class="form-check">

        <material-input
            :label="key"
            name="name"
            size="lg"
            v-if="getInputType(key) !== 'checkbox'"
            :type="getInputType(key)"
            :id="key"
            v-model="formData[key]"
            class="mt-2 mb-2"
            @change="validateField(key)"
        />
        <input
            v-else-if="getInputType(key) === 'checkbox'"
            type="checkbox"
            :id="key"
            v-model="formData[key]"
            class="form-check-input"
        />
        <label
            v-if="getInputType(key) === 'checkbox'"
            :for="key" class="form-label">
          {{ key }}
        </label>
        <small v-if="formDescriptions[key]" class="form-text text-muted w-100">{{ formDescriptions[key] }}</small>
        <small v-if="formErrors[key]" class="form-text text-danger w-100"> | {{ formErrors[key] }}</small>

      </div>
    </div>
    <div v-if="!formData.private && showExpertChoose" class="mb-3 choose-expert">
      <p>Choose an expert to connect to:</p>
      <p>Note that if you connect any app to the expert directly it will be accessible in the chat app and will be used in the API automatically.</p>
      <div class="row">
        <div v-if="experts.length === 0" class="col-12 text-center">
          <p>No experts available at the moment.</p>
          <button class="btn btn-primary" @click="redirectToExperts">Go to Experts</button>
        </div>
        <div v-else v-for="expert in experts" :key="expert.id" class="col-md-6 col-lg-4 mb-3">
          <div :class="'card shadow-sm' + (this.selectedExpert?.id === expert.id ? ' active' : '')">
            <div class="card-body text-center">
              <img :src="expert.attributes.profile_picture?.data?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'" class="rounded-circle mb-3" :alt="expert.attributes.name" style="width: 100px; height: 100px;" />
              <h5 class="card-title">{{ expert.attributes.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ expert.attributes.profession }}</h6>
              <p class="card-text">{{ expert.attributes.function_name }}</p>
              <p class="card-text">{{ expert.attributes.function_description }}</p>
            </div>
            <div class="d-flex justify-content-around">
              <button class="btn btn-primary btn-link m-0 w-100" @click="handleExpertSelection(expert)">Choose</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  components: {MaterialInput},
  props: {
    settings: {
      type: Object,
      required: true,
    },
    descriptions: {
      type: Object,
      required: true,
    },
    validations: {
      type: Object,
      required: true,
    },
    showExpertChoose: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data() {
    return {
      formData: this.settings,
      formDescriptions: this.descriptions,
      formValidations: this.validations,
      formErrors: {},
    };
  },
  computed: {
    ...mapState(['experts', 'selectedExpert']),
    filteredSettings() {
      const excludedKeys = ['database_type', 'tool_type', 'openapi_yml_url', 'api_key_type', 'id'];
      if(!this.showExpertChoose){
        excludedKeys.push('private')
      }
      return Object.keys(this.settings)
          .filter(key => !excludedKeys.includes(key))
          .reduce((obj, key) => {
            obj[key] = this.settings[key];
            return obj;
          }, {});
    },
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update', newValue);
      },
      deep: true,
    },
    settings: {
      handler(newVal) {
        this.formData = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['setSelectedExpert']),
    validateField(key) {
      const validation = this.formValidations[key];
      const value = this.formData[key];
      let error = '';

      if (!validation) {
        this.formErrors[key] = '';
        this.validateForm();
        return;
      }

      if (validation.required && !value) {
        error = `${key} is required.`;
      } else if (validation.type && typeof value !== validation.type) {
        error = `${key} must be a ${validation.type}.`;
      } else if (validation.minLength && value.length < validation.minLength) {
        error = `${key} must be at least ${validation.minLength} characters.`;
      } else if (validation.maxLength && value.length > validation.maxLength) {
        error = `${key} must be no more than ${validation.maxLength} characters.`;
      } else if (validation.pattern && !new RegExp(validation.pattern).test(value)) {
        error = `${key} is not in the correct format.`;
      } else if (validation.custom) {
        const customError = validation.custom(value);
        if (customError !== true) {
          error = customError;
        }
      }

      this.formErrors[key] = error; // Directly set the error without using this.$set
      this.validateForm();
    },
    validateForm() {
      const isValid = Object.values(this.formErrors).every(error => error === '');
      this.$emit('form-valid', isValid);
    },
    getInputType(key) {
      if (typeof this.settings[key] === 'boolean') {
        return 'checkbox';
      }
      if (key.toLowerCase().includes('url')) {
        return 'url';
      }
      if (key.toLowerCase().includes('apikey') || key.toLowerCase().includes('secret')) {
        return 'password';
      }
      return 'text';
    },
    redirectToExperts() {
      this.$router.push('/experts');
    },
    handleExpertSelection(expert) {
      this.setSelectedExpert(expert);
    },
  },
  mounted() {
    this.validateForm();
    this.formData = this.settings;
  }
};
</script>

<style scoped>

.choose-expert {
  padding-left: 1.73em;
}
.active {
  border: 2px solid #66bb6a;
}
</style>
