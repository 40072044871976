import { initializeApp } from 'firebase/app';
import { ref, onValue, off, get, getDatabase, update, set, push } from 'firebase/database';
import axios from 'axios';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Get the database instance
const db = getDatabase(firebaseApp); // Pass the initialized app to getDatabase

function getCookie(cookieString, name) {
    const cookies = cookieString ? cookieString.split('; ') : [];
    const targetCookie = cookies.find(cookie => cookie.startsWith(`${name}=`));
    return targetCookie ? targetCookie.split('=')[1] : null;
}

async function signInWithCustomTokenServe() {
    const cookieString = document.cookie; // Get the cookie string from the document
    const firebaseToken = getCookie(cookieString, 'firebaseToken'); // Use the getCookie function

    console.log("Cookie String:", cookieString); // Log the cookie string
    console.log("firebaseToken:", firebaseToken); // Log the retrieved token

    const auth = getAuth(firebaseApp); // Get the Auth instance

    try {
        await signInWithCustomToken(auth, firebaseToken); // Sign in with the custom token
        console.log("User signed in with custom token.");
    } catch (error) {
        console.error("Error signing in with custom token:", error);
        throw error; // Rethrow error for handling
    }
}

export const firebaseService = {
    async init() {

        await signInWithCustomTokenServe(); // Call the sign-in function
    },
    async signInWithCustomTokenServe() {
        const cookieString = document.cookie; // Get the cookie string from the document
        const firebaseToken = getCookie(cookieString, 'firebaseToken'); // Use the getCookie function


        const auth = getAuth(firebaseApp); // Get the Auth instance

        try {
            await signInWithCustomToken(auth, firebaseToken); // Sign in with the custom token
        } catch (error) {
            console.error("Error signing in with custom token:", error);
            throw error; // Rethrow error for handling
        }
    },
    async createChat(title, creatorId, expertId) {
        const db = getDatabase(); // Get the database instance
        const chatRef = push(ref(db, 'chats')); // Create a new chat reference

        const chatId = chatRef.key; // Get the new chat ID

        // Create updates object
        const updates = {};
        updates[`/chats/${chatId}`] = {
            title,
            created: new Date().toISOString(),
            lastMessage: null
        };
        updates[`/chat_members/${chatId}/${creatorId}`] = true; // Add creator to chat
        updates[`/user_chats/${creatorId}/${chatId}`] = true; // Add chat to creator's user

        if (expertId) {
            updates[`/experts/${expertId}/chats/${chatId}`] = true; // Add expert to chat
        }

        try {
            await update(ref(db), updates); // Update the database with all necessary chat data
            return chatId; // Return the new chat ID
        } catch (error) {
            console.error('Error creating chat:', error.message);
            throw new Error('Failed to create chat');
        }
    },

    async sendMessage(chatId, message) {
        const { sender, senderId, text, thoughts = [] } = message; // Destructure the necessary fields

        const db = getDatabase(); // Get the Firebase database instance
        const messageRef = push(ref(db, `messages/${chatId}`)); // Create a new reference for the message

        const messageData = {
            senderId,
            sender,
            text,
            thoughts,
            timestamp: Date.now(),
        };

        const updates = {
            [`/messages/${chatId}/${messageRef.key}`]: messageData, // Update messages
            [`/chats/${chatId}/lastMessage`]: messageData, // Update last message
        };

        try {
            await update(ref(db), updates); // Perform the update
            return { messageId: messageRef.key }; // Return the new message ID
        } catch (error) {
            throw new Error('Failed to send message'); // Handle errors
        }
    },

    listenToChat(chatId, callback) {
        const chatRef = ref(db, `messages/${chatId}`);
        const listener = onValue(chatRef, (snapshot) => {
            const data = snapshot.val();
            callback(data);
        });

        // Return an unsubscribe function to stop listening to changes
        return () => {
            off(chatRef, 'value', listener);
        };
    },

    async getUserChats(userId) {
        const db = getDatabase();
        const userChatsRef = ref(db, `user_chats/${userId}`);

        try {
            const snapshot = await get(userChatsRef);

            // Extract chat IDs where the user is a member
            const userChats = snapshot.val() || {};
            return Object.keys(userChats).filter(chatId => userChats[chatId]); // Return chat IDs
        } catch (error) {
            console.error("Failed to fetch user chats", error);
            throw new Error('Failed to fetch user chats');
        }
    },

    async getChatDetails(chatId) {
        const db = getDatabase(); // Get the database instance
        const chatRef = ref(db, `chats/${chatId}`); // Reference to the specific chat

        try {
            const snapshot = await get(chatRef); // Fetch the chat details
            if (snapshot.exists()) {
                return snapshot.val(); // Return the chat details if found
            } else {
                throw new Error('Chat not found');
            }
        } catch (error) {
            console.error("Error fetching chat details:", error);
            throw new Error('Failed to get chat details');
        }
    },

    async getChatWithMessages(chatId) {
        const db = getDatabase(); // Get the database instance
        const chatRef = ref(db, `chats/${chatId}`); // Reference to the specific chat
        const messagesRef = ref(db, `messages/${chatId}`); // Reference to messages for the chat

        try {
            const chatSnapshot = await get(chatRef); // Fetch chat details
            const messagesSnapshot = await get(messagesRef); // Fetch messages for the chat

            const chatDetails = chatSnapshot.exists() ? chatSnapshot.val() : null;
            const messages = messagesSnapshot.exists() ? messagesSnapshot.val() : null;

            return {
                chatDetails,
                messages: messages || {}
            };
        } catch (error) {
            console.error("Error fetching chat with messages:", error);
            throw new Error('Failed to fetch chat with messages');
        }
    },

    // New method to send an invitation
    async sendInvitation(invitationData) {
        try {
            // Use the Netlify function for sending invitations
            const response = await axios.post('/.netlify/functions/sendInvitation', invitationData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include' // If credentials are needed
            });
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Error response from Netlify function:', error.response.data);
            } else {
                console.error('Failed to send invitation:', error.message);
            }
            throw error;
        }
    },
    listenToInvitations(userId, callback) {
        const invitationsRef = ref(db, `invitations/${userId}`);

        onValue(invitationsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                //console.log("Listening and getting this data:", data);
                callback(data);
            } else {
                //console.log("No invitations found.");
            }
        });

        // No need to stop listening, so we do not return off()
    },
    async getPendingNotifications(userId) {
        const invitationsRef = ref(db, `invitations/${userId}`);

        try {
            const snapshot = await get(invitationsRef);
            if (!snapshot.exists()) {
                return null; // or return an empty object
            }

            const data = snapshot.val();
            if (!data) {
                return {};
            }

            // Filter only the invitations with status 'pending'
            const pendingInvitations = Object.entries(data)
                .filter(([, value]) => value.status === 'pending') // Skip destructuring key, use only value
                .reduce((obj, [key, value]) => {
                    obj[key] = value;
                    return obj;
                }, {});

            return pendingInvitations;
        } catch (error) {
            console.error("Error fetching pending notifications:", error);
            throw error; // Rethrow the error for further handling
        }
    },
    async acceptInvitation(userId, chatId, invitationId) {
        const invitationRef = ref(db, `invitations/${userId}/${invitationId}`);
        const chatMembersRef = ref(db, `chat_members/${chatId}/${userId}`); // Reference for adding user to chat members

        try {
            // Update the invitation status to 'accepted'
            await update(invitationRef, { status: 'accepted' });

            // Add userId to the chat members
            await set(chatMembersRef, true); // Sets the user as a member of the chat
            console.log(`User ${userId} added to chat members for chatId: ${chatId}`);

        } catch (error) {
            console.error("Error accepting invitation:", error);
            throw error; // Rethrow the error for further handling
        }
    },
    async declineInvitation(userId, invitationId) {
        const invitationRef = ref(db, `invitations/${userId}/${invitationId}`);

        try {
            // Update the invitation status to 'accepted'
            await update(invitationRef, { status: 'decline' });

        } catch (error) {
            console.error("Error accepting invitation:", error);
            throw error; // Rethrow the error for further handling
        }
    },
};