<template>
  <div class="modal fade" tabindex="-1" ref="modal" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" @click="handleCancel" aria-label="Close">
            <i class="material-icons-round fs-5">close</i>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ message }}</p>
        </div>
        <div class="modal-footer">
          <button v-if="showCancel" type="button" class="btn btn-secondary m-0" @click="handleCancel">Cancel</button>
          <button type="button" class="btn btn-primary m-0" @click="handleOk">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap/dist/js/bootstrap";

export default {
  props: {
    title: {
      type: String,
      default: 'Alert',
    },
    message: {
      type: String,
      default: '',
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bsModalInstance: null,
    };
  },
  mounted() {
    this.bsModalInstance = new Modal(this.$refs.modal);
  },
  methods: {
    openModal() {
      this.bsModalInstance.show();
    },
    closeModal() {
      this.bsModalInstance.hide();
    },
    handleOk() {
      this.closeModal();
      this.$emit('ok'); // Emit "ok" event for OK action
    },
    handleCancel() {
      this.closeModal();
      this.$emit('cancel'); // Emit "cancel" event for Cancel action
    },
  },
};
</script>
