<template>
  <div>
    <ul class="list-group">
      <li
          v-for="(key, index) in apiKeys"
          :key="index"
          class="list-group-item d-flex justify-content-between align-items-center"
      >
        <material-input
          :id="'key-' + index"
          :type="inputTypes[index]"

          v-model="localKeys[index]"
          disabled
        />
        <button class="btn btn-sm btn-secondary mb-0" @click="toggleInputType(index)">
          <i class="material-icons-round opacity-10 cursor-pointer m-0 fs-6">visibility</i>
        </button>
        <button class="btn btn-sm btn-info mb-0" @click="copyToClipboard(localKeys[index])">
          <i class="material-icons-round opacity-10 cursor-pointer m-0 fs-6">content_copy</i>
        </button>

        <button class="btn btn-sm btn-danger mb-0" @click="removeKey(index)">
          <i class="material-icons-round opacity-10 cursor-pointer fs-6">delete</i>
        </button>
      </li>
    </ul>
    <div class="mt-3">
      <button class="btn btn-primary" @click="generateKey">Add Key</button>
    </div>
  </div>
  <ModalAlert
      v-if="showModal"
      ref="modalAlert"
      :title="'Success'"
      :message="'API Key copied to clipboard!'"
      :showCancel="false"
  />
</template>

<script>
import MaterialInput from "../../components/MaterialInput.vue";
import ModalAlert from "./ModalAlert.vue";

export default {
  name: "ApiKeys",
  components: {ModalAlert, MaterialInput },
  props: {
    apiKeys: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localKeys: [...this.apiKeys], // Create a local copy of apiKeys
      inputTypes: this.apiKeys.map(() => "password"), // Initialize inputTypes array
      showModal: false,
    };
  },
  methods: {
    generateKey() {
      const length = Math.floor(Math.random() * (48 - 36 + 1)) + 36;
      const randomString = Array(length)
          .fill(0)
          .map(() => Math.random().toString(36).charAt(2))
          .join("");
      const newApiKey = `bbot_${randomString}`;
      this.localKeys.push(newApiKey); // Update localKeys with new key
      this.inputTypes.push("password"); // Initialize new input type for the new key
      this.$emit("add-key", newApiKey); // Emit new key to parent
    },
    removeKey(index) {
      this.localKeys.splice(index, 1); // Remove from localKeys
      this.inputTypes.splice(index, 1); // Remove corresponding input type
      this.$emit("remove-key", index); // Emit index to parent
    },
    toggleInputType(index) {
      // Toggle the input type for the specific index
      this.inputTypes[index] = this.inputTypes[index] === "password" ? "text" : "password";
    },
    async copyToClipboard(apiKey) {
      try {
        await navigator.clipboard.writeText(apiKey);
        this.showModal = true;
        this.$nextTick(() => {
          this.$refs.modalAlert.openModal(); // Open the modal after it's rendered
        });
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    }
  },
  watch: {
    localKeys: {
      handler(newKeys) {
        this.$emit("update-keys", newKeys); // Emit updated keys to parent
      },
      deep: true, // Watch deeply for changes in the array
    },
  },
};
</script>
