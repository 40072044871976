<template>
  <div class="timeline-line" v-if="line">
    <p class="text-align-right">Upcoming</p>
  </div>
  <div class="mb-3 timeline-block">
    <span class="timeline-step p-3" :class="`bg-${color}`">
      <i class="material-icons opacity-10" :class="icon.class">{{
        icon.component
      }}</i>
    </span>
    <div class="timeline-content">
      <h6
        class="mb-0 text-sm font-weight-bold"
        :class="$parent.darkMode ? 'text-white' : 'text-dark'"
      >
        {{ title }}
      </h6>
      <p class="mt-1 mb-0 text-xs text-secondary font-weight-normal">
        {{ dateTime }}
      </p>
      <p
        v-if="description"
        class="mt-3 mb-2 text-sm"
        :class="$parent.darkMode ? 'text-white' : 'text-dark'"
      >
        {{ description }}
      </p>
      <material-button
          v-if="link !== ''"
          @click="goTo(link)"
          class="btn-sm"
          variant="gradient"
          color="primary"
          fullWidth
      >
        more info
      </material-button>

    </div>
  </div>
</template>
<script>
import MaterialButton from "../../components/MaterialButton.vue";

export default {
  name: "TimelineItem",
  components: {MaterialButton},
  props: {
    color: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      component: String,
      class: String,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    line: {
      type: Boolean,
      default: false,
    }
  },
  methods:{
    goTo(link) {
      this.$router.push(link);
    }
  }
};
</script>

<style scoped>
.timeline-line{
  width: 100%;
  border-top: #e5e5e5 2px dashed;
}
.text-align-right{
  text-align: right;
}
</style>