<template>
  <div v-if="experts.length === 0" class="container p-0 text-center">
    <div class="col-12">
      <div class="d-flex justify-content-center align-items-center mb-3">
        <img
            class="w-25 me-3 mb-0 backdrop"
            src="@/assets/img/illustrations/gym-dynamic-gradient.png"
            alt="logo"
        />
        <h2>Train Experts</h2>
      </div>
      <p>No experts available at the moment.</p>
      <button class="btn btn-primary" @click="redirectToExperts">Go to Experts</button>
    </div>
  </div>
  <div v-else class="container p-0">
    <!-- Expertenauswahl -->
    <div v-if="currentStep === 'expert'">
      <ExpertsListTraining @expert-selected="handleExpertSelected" />
    </div>
    <div v-if="currentStep === 'selectModel'">
      <ModelVersions @model-selected="handleModelSelected" />
    </div>
    <!-- Schritt für die Auswahl der Fähigkeiten -->
    <div class="p-3" v-if="currentStep === 'selectAbility'">
      <h3 class="text-center">
        Select what to Train
        <DocumentationLink
            popoverTitle="Train Experts"
            class="z-index-5 documentation-link"
            description="Learn more about the Training of an Expert"
            docLink="https://docs.b-bot.space/essentials/training#choose-the-base-sentence"
        />
      </h3>

      <button @click="currentStep = 'select'" class="btn btn-primary mt-3 w-100">Train Personality</button>
      <ExpertAbilities :abilities="selectedExpert.attributes.abilities.data" @ability-selected="handleAbilitySelection"/>
    </div>


    <!-- Auswahl der Karten -->
    <div v-if="currentStep === 'select'">
      <CardsSelection :cards="qaPairs" @card-selected="selectCard" @save-qa-pairs="saveQAPairs"/>
      <!-- Button zum Weitergehen zur Kartenbearbeitung -->
      <div class="position-fixed proceed container mt-4">
        {{ generatedCards.filter(c => c.selected).length + cards.filter(c => c.selected).length}}
        <button @click="saveAndProceed" class="btn btn-success w-100 mt-3">Proceed to Training Cards</button>
      </div>
    </div>

    <!-- Kartenbearbeitung -->
    <div v-if="currentStep === 'edit'">
      <div class="container">
        <div class="card p-3 mb-3">
          <h2>
            Add details to the Training Session
            <DocumentationLink
                popoverTitle="Training Session"
                class="z-index-5 documentation-link"
                description="Learn more about the editing of Situations"
                docLink="https://docs.b-bot.space/essentials/training#edit-the-situations"
            />
          </h2>
          <p>Add a name and Description to the Session so you can recall what you trained</p>
          <material-input
              id="name"
              class="mb-3"
              type="text"
              label="Name"
              name="name"
              size="lg"
              v-model="trainingSessionName"
          />

          <v-md-editor
              left-toolbar="undo redo clear"
              right-toolbar="fullscreen"
              mode="edit"
              class="form-control mt-2"
              rows="2"
              v-model="trainingSessionDescription">
          </v-md-editor>
        </div>
      </div>
      <CardsEdit :cards="cards.filter(c => c.selected)" @duplicate-card="handleDuplicateCard" @add-card="handleAddCard" @delete-card="handleDeleteCard" @save-cards="updateTrainingData"/>
    </div>

    <!-- Training der AI -->
    <div class="card" v-if="currentStep === 'train'">
      <h2 class="text-center">
        Train your Expert
        <DocumentationLink
            popoverTitle="Train your Expert"
            class="z-index-5 documentation-link"
            description="Learn more about the Expert Training"
            docLink="https://docs.b-bot.space/essentials/training#train-the-expert"
        />
      </h2>
      <TrainingInfo @start-training="startTraining" @stop-training="stopTraining" :animate="shouldAnimate" :showCancel="showCancel" :jobId="jobId" :statusMessage="currentStatusMessage" />
      <div class="container">
        <button @click="currentStep = 'edit'" class="btn btn-secondary d-none">Back: Edit Cards</button>

        <button v-if="!trainingInProgress" @click="currentStep = 'expert'" class="btn btn-secondary w-100">Start a new Training</button>

      </div>
    </div>
  </div>
</template>

<script>
import ExpertsListTraining from './components/ExpertListTraining.vue'; // Adjust the import path as needed
import ExpertAbilities from './components/ExpertAbilities.vue'; // Pfad ggf. anpassen
import CardsSelection from './components/CardsSelection.vue';
import CardsEdit from './components/CardsEdit.vue';
import TrainingInfo from './components/TrainingInfo.vue';
import {mapActions, mapMutations, mapState} from "vuex";
import ModelVersions from "@/views/components/ModelVersions.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import DocumentationLink from "./components/DocumentationLink.vue"; // Import the TrainingInfo component


export default {
  components: {
    DocumentationLink,
    MaterialInput,
    ModelVersions,
    ExpertsListTraining,
    ExpertAbilities,
    CardsSelection,
    CardsEdit,
    TrainingInfo,
  },
  data() {
    return {
      currentStep: 'expert',
      selectedExpert: null,
      trainingOption: null, // 'personality' oder 'ability'
      cards: [],
      selectedCard: null,
      trainingSessionName: "",
      trainingSessionDescription: "",
    };
  },
  computed: {
    ...mapState(['experts', 'selectedExpert', 'trainingInProgress', 'trainingSuccess', 'selectedTrainingSession', 'qaPairs', 'color', 'selectedModel', 'generatedCards','traininsSessionQaPairs', 'currentStatusMessage', 'shouldAnimate', 'showCancel', 'jobId']),
  },
  watch: {
    '$route.hash'(newHash) {
      const step = newHash.replace('#', '');
      if (step) {
        this.currentStep = step;
      } else {
        this.currentStep = 'expert';  // Default step if none is provided
      }
    }
  },
  mounted() {
    this.generatedCards = []
    this.cards = []
    if(this.trainingInProgress){
      this.currentStep = 'train';
    }

    const step = this.$route.hash.replace('#', '');
    if (step) {
      this.currentStep = step;
    } else {
      this.currentStep = 'expert';  // Default step if none is provided
    }
    this.cards = [];
    this.$store.dispatch('fetchQAPairs');

    //this.fetchTrainingSessions();
    this.showEveryDisplay();
    if (this.trainingInProgress && this.jobId) {
      this.startStatusPolling( this.jobId, "openai");
    }
  },
  beforeUnmount() {
    if (this.statusCheckInterval) {
      clearInterval(this.statusCheckInterval);
      this.statusCheckInterval = null;
    }
  },
  methods: {
    ...mapMutations(["showEveryDisplay", "clearSelectedTraininsSessionQaPairs", "setShowCancel"]),
    ...mapActions([
        'createModel',
        'saveQAPairs',
        'startTrainingSession',
        'stopTrainingSession',
        'startStatusPolling',
        'updateUIBasedOnStatus',
        'updateModelBasedOnStatus',
        'fetchQAPairs',
        'listTrainingSessions',
        'toggleQAPairSelection',
        'startTrainingInProgress',
        'stopTrainingInProgress',
        'fetchTrainingSessions',
    ]),
    handleExpertSelected(expert) {

      this.selectedExpert = expert;
      this.currentStep = 'selectModel'; // Weiter zum nächsten Schritt
      this.trainingSessionName = "Session_" + this.selectedExpert?.attributes.name + "_" + new Date().toLocaleDateString();
      this.trainingSessionDescription = "Training Session for:" + this.selectedExpert?.attributes.name + ", adding more clarity to the experts abilities. and personality." + this.selectedExpert?.attributes.description;

    },
    handleAbilitySelection() {

      // Speichere die ausgewählte Fähigkeit und/oder setze den Trainingsprozess fort
      this.currentStep = 'select'; // Optional, direkt zum Training wechseln
    },
    handleModelSelected(){
      this.clearSelectedTraininsSessionQaPairs();
      this.cards = [];
      this.traininsSessionQaPairs = [];
      this.generatedCards = [];
      this.qaPairs = [];
      this.setShowCancel(false);
      this.currentStep = 'selectAbility'; // Optional, direkt zum Training wechseln
    },
    selectCard(card) {
      this.$store.dispatch('toggleQAPairSelection', card.id);
    },
    async startTraining() {
      try {
        this.startTrainingInProgress();

        // Log selected expert attributes

        // Define standard tools
        const standardTools = [
          {
            type: "function",
            function: {
              name: "tavily_search_results_json",
              description: "Searches for information using Tavily.",
              parameters: {
                type: "object",
                properties: {
                  query: {
                    type: "string",
                    description: "The search query."
                  }
                },
                required: ["query"]
              }
            }
          },
          {
            type: "function",
            function: {
              name: "wolfram",
              description: "Performs a computation using Wolfram Alpha.",
              parameters: {
                type: "object",
                properties: {
                  query: {
                    type: "string",
                    description: "The computation query."
                  }
                },
                required: ["query"]
              }
            }
          }
        ];

        // Map expert tools
        const expertTools = Array.isArray(this.selectedExpert?.attributes?.apps)
            ? this.selectedExpert.attributes.apps.map(app => ({
              type: "function",
              function: {
                name: app.name,
                description: app.description || "No description available.",
                parameters: {
                  type: "object",
                  properties: app.parameters,
                  required: Object.keys(app.parameters)
                }
              }
            }))
            : [];

        // Log mapped expert tools

        // Prepare training data
        const trainingData = this.cards
            .filter(c => c.selected)
            .map((card, cardIndex) => {
              const messages = [];


              card.messages.forEach((message, messageIndex) => {
                const toolCalls = {};

                if (message.role === "user") {
                  messages.push({
                    role: "user",
                    content: message.content || ""
                  });
                }

                if (message.role === "assistant") {
                  if (message.thoughts?.length > 0) {
                    message.thoughts.forEach((thought, thoughtIndex) => {
                      if (thought?.tool) {
                        const toolCallId = `call_${Date.now()}_${cardIndex}_${messageIndex}_${thoughtIndex}_${Math.floor(Math.random() * 1000000)}`;

                        const toolCall = {
                          id: toolCallId,
                          type: "function",
                          function: {
                            name: thought.tool,
                            arguments: JSON.stringify(thought.tool_input)
                          }
                        };

                        const assistantMessage = {
                          role: "assistant",
                          tool_calls: [toolCall],
                        };

                        messages.push(assistantMessage);

                        toolCalls[toolCallId] = {
                          tool_call_id: toolCallId,
                          content: thought.tool_output || ""
                        };

                        // Add the tool response immediately after the tool call
                        messages.push({
                          role: "tool",
                          tool_call_id: toolCallId,
                          content: typeof toolCalls[toolCallId].content === 'string'
                              ? toolCalls[toolCallId].content
                              : JSON.stringify(toolCalls[toolCallId].content)
                        });
                      }
                    });

                    // Add the assistant's response message (if any) after processing all thoughts
                    messages.push({
                      role: "assistant",
                      content: message.content || ""
                    });
                  } else {
                    messages.push({
                      role: "assistant",
                      content: message.content || ""
                    });
                  }
                }
              });


              return {
                messages,
                tools: [...standardTools, ...expertTools]
              };
            });

        // Log final arrays

        this.$store.commit('setStatusMessage', "Starting...");

        const trainingSessionResponse = await this.startTrainingSession({ trainingData });

        if (trainingSessionResponse?.status?.id) {
          this.$store.commit('setJobId', trainingSessionResponse.status.id);
          this.startStatusPolling(trainingSessionResponse.status.id, this.selectedModel.attributes.provider);
          this.$store.commit('setShowCancel', true);
          this.showCancel = true;
        }
      } catch (error) {
        console.error("Error During Training Session Start:", error);
        this.$store.commit('setStatusMessage', "Error occurred during training.");
      }
    },
    async stopTraining() {
      //const jobId = this.jobId;
      await this.stopTrainingSession();
      this.$store.commit('setStatusMessage', "Stopping...");
      this.stopTrainingInProgress()
      this.$store.commit('setShowCancel', false);
      this.showCancel = false;
    },
    startStatusPolling(fineTuningId, provider) {
      this.$store.dispatch('startStatusPolling', { fineTuningId, provider});
    },
    setStatusMessage(message){
      this.currentStatusMessage = message;
    },
    setStep(step){
      this.currentStep = step;
    },
    setCards(cards) {
      this.cards = cards.map((card, index) => {
        // Ensure there is content in at least one message to include the card
        const hasContent = card.messages.some(message => message.content.trim() !== '');

        if (hasContent) {
          return {
            id: index + 1, // Assuming unique ID for each card
            name: `Card ${index + 1}`, // Name based on its order
            messages: card.messages.map(message => {
              // Ensure message content is not just whitespace
              const content = message.content.trim() !== '' ? message.content : 'Default content';
              const thoughts = message.thoughts;
              return { ...message, content, thoughts };
            }),
            selected: false, // Add selected attribute set to false by default
          };
        }
        return null;
      }).filter(card => card !== null); // Filter out any null entries
    },
    handleAddCard(position) {
      const newCard = {
        id: this.cards.length + 1, // Generiere eine eindeutige ID
        messages: [
          { role: 'user', content: 'New Message' },
          { role: 'assistant', content: 'New Message' },
        ],
        selected: true,
      };
      if (position === 'top') {
        this.cards.unshift(newCard);
      } else {
        this.cards.push(newCard);
      }
      // Aktualisiere die Liste, um Reaktivität zu gewährleisten
      this.cards = [...this.cards];
    },
    handleDuplicateCard(cardIndex) {
      const cardToDuplicate = { ...this.cards[cardIndex] };
      cardToDuplicate.id = this.cards.length + 1; // Generiere eine neue ID
      this.cards.splice(cardIndex + 1, 0, cardToDuplicate); // Füge die duplizierte Karte nach der Originalkarte ein
      this.cards = [...this.cards]; // Aktualisiere die Kartenliste
    },
    handleDeleteCard(cardIndex) {
      this.cards.splice(cardIndex, 1);
      this.cards = [...this.cards]; // Aktualisiere die Kartenliste
    },
    async saveAndProceed() {
      this.setShowCancel(false);
      // Filter out selected QA pairs and generated cards

      const selectedQAPairs = this.qaPairs.filter(qaPair => qaPair.selected);
      const selectedTraininsSessionQaPairs = this.traininsSessionQaPairs.filter(traininsSessionQaPair => traininsSessionQaPair.selected);
      const selectedGeneratedCards = this.generatedCards.filter(card => card.selected);
      console.log("selectedGeneratedCards: ",selectedGeneratedCards)
      const selectedCards = [...selectedQAPairs, ...selectedGeneratedCards, ...selectedTraininsSessionQaPairs];

      // Ensure all selected cards have a messages property
      let validSelectedCards = selectedCards.filter(card => card.attributes.set && Array.isArray(card.attributes.set));
      // Transform the combined selected cards into the format expected by the backend
      const trainingData = validSelectedCards.map(card => ({
        messages: card.attributes.set.map(message => ({
          role: message.role,
          content: message.content,
          thoughts: message.thoughts
        }))
      }));

      try {
        // Submit the transformed data to the backend
        let sessionName = this.trainingSessionName;
        let sessionDescription = this.trainingSessionDescription;
        const data = await this.$store.dispatch('addTrainingSession', { trainingData, sessionName, sessionDescription });

        this.cards = data.training_data.session.map((qaPair, index) => ({
          id: index,  // Ensure this ID is meaningful for your frontend logic
          messages: qaPair.messages,
          selected: true
        }));
        // Optionally update UI state or navigate to the next step
        this.currentStep = 'edit';
      } catch (error) {
        console.error("Failed to add training session:", error);
        // Handle submission error
      }
    },
    async updateTrainingData() {
      if (!this.selectedTrainingSession || !this.selectedTrainingSession.id) {
        console.error('No training session selected');
        return;
      }
      this.setShowCancel(false);
      // Transform cards into the expected training data format
      const trainingData = this.cards.map(card => ({
        messages: card.messages.map(message => ({
          role: message.role,
          content: message.content,
          thoughts: message.thoughts
        }))
      }));
      // Prepare the payload with the updated training data
      const dataToUpdate = {
        name: this.trainingSessionName,
        description: this.trainingSessionDescription,
        training_data: { session: trainingData },
        experts: this.selectedExpert.id
      };

      // Dispatch the updateTrainingSession action with the session ID and the updated data
      try {
        await this.$store.dispatch('updateTrainingSession', {
          sessionId: this.selectedTrainingSession.id,
          data: dataToUpdate
        });
      } catch (error) {
        console.error('Failed to update training session:', error);
      }
      this.updateUIBasedOnStatus("ready")
      this.currentStep = "train";
    },
    redirectToExperts() {
      // Redirect to the /experts route
      this.$router.push('/experts');
    },
  },
};
</script>

<style scoped>
.proceed{
  bottom: 0;
  background: white;
  border-radius: 12px;
  z-index: 5;
}

.backdrop{
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
.documentation-link {
  display: inline; /* Ensure the icon appears inline */
  vertical-align: middle; /* Align vertically with the text */
  margin-left: 0px; /* Space between title and icon */
}
</style>