<template>
  <div class="container mt-5 mb-5">
    <!-- Session Information Accordion -->
    <div class="accordion" id="sessionInfoAccordion">
      <div class="accordion-item">
        <h2 class="accordion-header" id="sessionInfoHeading">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sessionInfoCollapse" aria-expanded="false" aria-controls="sessionInfoCollapse">
            Session Information
          </button>
        </h2>
        <div id="sessionInfoCollapse" class="accordion-collapse collapse" aria-labelledby="sessionInfoHeading" data-bs-parent="#sessionInfoAccordion">
          <div class="accordion-body">
            <p>Training Option: {{ trainingOption }}</p>
            <p>Selected Expert: {{ selectedExpertName }}</p>
          </div>
        </div>
      </div>
    </div>


    <div class="training-animation-container">
      <vue3-lottie ref="lottie"
         :animationData="animationData"
         height="60vh"
         width="100%"
      />
      <a href="#" :class="`btn btn-primary position-absolute mb-0 ${( showCancel ? 'disabled' :'' )}`  "  @click.prevent="triggerTraining">{{ statusMessage }}</a>
    </div>

    <a href="#" class="btn btn-danger w-100" v-if="showCancel" @click.prevent="triggerStopTraining">
      Stop Training
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Vue3Lottie from 'vue3-lottie';
import logoXD from "@/assets/img/illustrations/lottieLoading.json";

export default {
  components: {
    Vue3Lottie,
  },
  props: {
    statusMessage: String,
    showCancel: Boolean,
    jobId: String,
    animate: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    animate(newVal) {
      if (newVal) {
        this.$refs.lottie.play();
      } else {
        this.$refs.lottie.stop();
      }
    },
    statusMessage() {
      // Handle any additional logic or side effects if needed
    }
  },
  data() {
    return {
      animationData: logoXD,
      trainingInProgress: false,
      statusCheckInterval: null, // To store the interval ID for status checks
    };
  },
  computed: {
    ...mapState(['trainingOption', 'selectedExpert', 'selectedTrainingSession']),
    selectedExpertName() {
      return this.selectedExpert ? this.selectedExpert.name : 'None';
    },
  },
  mounted() {
    // Stop the lottie animation shortly after the component mounts, as a placeholder action
    setTimeout(() => {
      this.$nextTick(() => {
        if (this.animate) {
          this.$refs.lottie.play();
        } else {
          this.$refs.lottie.stop();
        }
      });
    }, 100);
  },
  beforeUnmount() {
    // Clear the status checking interval when the component is destroyed
    if (this.statusCheckInterval) {
      clearInterval(this.statusCheckInterval);
    }
  },
  methods: {
    triggerTraining() {
      // Emit an event when the user clicks to start training
      this.$emit('start-training');
      // UI changes, like starting an animation, can also be handled here if needed
      this.trainingInProgress = true;
      this.$refs.lottie.play();
    },
    triggerStopTraining(){
      //this.$refs.lottie.stop();
      this.$emit('stop-training');
      this.trainingInProgress = false;

    },
    stopAnimation(){
      this.$refs.lottie.stop();
    }
  }
};
</script>

<style scoped>
.training-animation-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay-start-training {
  position: absolute;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent white overlay */
  color: #000;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
}
</style>
