<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-5px"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
    :class="isAbsolute ? 'mt-4' : 'mt-0'"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" :color="color" />
      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <!-- <material-input id="search" label="Search here" /> -->
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item pr-3 d-flex align-items-center">
            <router-link
              :to="{ name: 'Profile' }"
              class="px-0 nav-link font-weight-bold lh-1"
              :class="color ? color : 'text-body'"
            >
              <i class="material-icons" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'">
                account_circle
              </i>
            </router-link>
          </li>
          <li class="pr-3 nav-item d-flex align-items-center">
            <a
                class="p-0 nav-link lh-1"
                @click="toggleConfigurator"
                :class="color ? color : 'text-body'"
            >
              <i class="material-icons fixed-plugin-button-nav cursor-pointer">
                settings
              </i>
            </a>
          </li>
          <li class="nav-item d-xl-none pr-3 d-flex align-items-center">
            <a
              href="#"
              @click="toggleSidebar"
              class="p-0 nav-link text-body lh-1"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>

          <li
            class="nav-item pr-3 dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              href="#"
              class="p-0 nav-link lh-1"
              :class="[color ? color : 'text-body', showMenu ? 'show' : '']"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="material-icons cursor-pointer"> notifications </i>
            </a>
            <ul
                class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
                :class="showMenu ? 'show' : ''"
                aria-labelledby="dropdownMenuButton"
            >

              <!-- Check if there are notifications -->
              <li v-if="notifications.length === 0">
                <a class="dropdown-item border-radius-md text-center" href="javascript:;">
                  <div class="py-1">
                    <p class="mb-0 text-sm text-secondary">
                      No notifications
                    </p>
                  </div>
                </a>
              </li>

              <!-- Render notifications if present -->
              <li v-else v-for="notification in notifications" :key="notification.id" class="mb-2">
                <router-link v-if="notification.type==='training_status'" :to="notification.link" class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                          :src="notification?.image"
                          class="avatar avatar-sm me-3"
                          alt="notification image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">{{ notification?.title }}</span>
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{ notification?.time }}
                      </p>
                    </div>
                  </div>
                </router-link>
                <span v-else class="dropdown-item border-radius-md">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                          :src="notification?.image"
                          class="avatar avatar-sm me-3"
                          alt="notification image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">{{ notification?.title }}</span>
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        {{ notification?.createdAt }}
                      </p>

                      <material-button
                          @click="handleAccept(notification?.chatId, notification?.invitationId)"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="primary"
                          fullWidth
                      >
                        accept
                      </material-button>
                      <material-button
                          @click="handleDecline(notification?.chatId, notification?.invitationId)"
                          class="my-4 mb-2"
                          variant="gradient"
                          color="primary"
                          fullWidth
                      >
                        decline
                      </material-button>
                    </div>
                  </div>
                </span>
              </li>
              <material-button class="w-100"  @click="clearNotifications()">
                clear Notifications
              </material-button>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
//import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";

import Breadcrumbs from "../Breadcrumbs.vue";
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
    };
  },
  props: ["minNav", "color"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["clearNotifications", "acceptInvitation", "declineInvitation"]),

    toggleSidebar() {
      this.navbarMinimize();
    },
    handleAccept(chatId, invitationId) {
      const userId = this.user.sub; // Get the logged-in user's ID
      this.acceptInvitation({ userId, chatId, invitationId });
    },
    handleDecline(chatId, invitationId) {

      const userId = this.user.sub; // Get the logged-in user's ID
      this.declineInvitation({ userId, chatId, invitationId });
    },
  },
  components: {
    Breadcrumbs,
    MaterialButton
    //MaterialInput,
  },
  computed: {
    ...mapState(["isRTL", "isAbsolute", "user", "notifications"]),

    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.pr-3{
  padding-right: 1rem;
}
.dropdown-menu{
  height: 300px;
  overflow-y: scroll;
}
</style>
