<template>
  <div class="d-flex justify-content-center align-items-center vh-100">
    <h1>Loading...</h1>
  </div>
</template>

<script>

export default {
  name: "Home",
  components: {
  },
};
</script>
