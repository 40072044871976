<template>
  <div class="chat-input">
    <material-textarea
        ref="messageInput"
        id="message"
        v-model="message"
        @input="handleInput"
        placeholder="Type a message..."
        name="message"
        class="message-input flex-grow-1"
        @keydown="handleKeydown"
        :disabled="isLoading"
    />
    <div class="align-items-center">
      <div class="col-auto">
        <ChatTopBar
            @toggle-conversations-modal="handleToggleConversationsModal"
            @toggle-abilites-modal="handleToggleAbilitiesModal"
            @toggle-models-modal="handleToggleModelsModal"
            @toggle-settings-modal="handleToggleSettingsModal"
            @toggle-connectors-modal="handleToggleConnectorsModal"
            @press-back-button="handleBackButton"
            @start-new-chat="startNewChat"
            @toggle-add-file-modal="handleToggleAddFile"
        />
      </div>
      <div class="col-auto">
        <material-button
            class="action-btn w-100 h-50px"
            variant="gradient"
            color="primary"
            size="md"
            @click="sendMessage"
            :disabled="isLoading"
        >
          <i class="fa fa-paper-plane"></i>
        </material-button>
      </div>
    </div>
  </div>
</template>


<script>
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import {mapActions, mapMutations, mapState} from 'vuex';
import ChatTopBar from "@/views/components/ChatTopBar.vue";
const { VUE_APP_MAIN_API_URL } = process.env;

export default {
  name: 'ChatInput',
  data() {
    return {
      message: '',
      showConversationsModal: false,
      id: '',
    };
  },
  components: {
    ChatTopBar,
    MaterialButton,
    MaterialTextarea,
  },
  computed: {
    ...mapState(['chatMessages','selectedTemplateText','statistics','experts', 'selectedExpert', 'selectedModel', 'user', 'isLoading', 'selectedAbility', 'toolActivation' ,'author', 'chatDocumentUrls', 'currentChat']),
  },
  watch: {
    selectedTemplateText(newVal) {
      this.$refs.messageInput.$el.querySelector('textarea').value = newVal;
    },
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    ...mapMutations(['setSelectedTemplateText']),
    ...mapActions(['saveMessage', 'setIsLoading', 'setIsErrorInRequest', 'updateUnsavedChanges', 'updateStatistics', 'createNewChat']),
    handleBeforeUnload(e) {
      if (this.$store.state.unsavedChanges) {
        // Most browsers no longer allow custom messages here
        e.preventDefault();
        e.returnValue = '';
      }
    },
    updateUserMetadata(metadataKey, newValue) {
      // Dispatch the Vuex action
      this.$store.dispatch('updateUserMetadata', { metadataKey, newValue });
    },
    handleToggleConversationsModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-conversations-modal');
    },
    handleToggleAbilitiesModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-abilites-modal');
    },
    handleToggleModelsModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-models-modal');
    },
    handleToggleSettingsModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-settings-modal');
    },
    handleToggleConnectorsModal() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-connectors-modal');
    },
    handleToggleAddFile() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('toggle-add-file-modal');
    },
    startNewChat(chatname){
      this.$emit('start-new-chat', chatname);
    },
    handleBackButton() {
      // Emit the event to the parent component, ChatWindow
      this.$emit('back-button');
    },
    async sendMessage() {
      const croppedMessage = this.message.slice(0, 50);
      // Start a new chat only if chatMessages array is empty
      if (this.chatMessages.length === 0) {
        await this.createNewChat(croppedMessage);
      }
      this.$store.dispatch('setIsErrorInRequest', false);

      this.$store.dispatch('setIsLoading', true);
      this.$emit('scroll-down');

      this.updateUnsavedChanges(true); // Dispatch action to Vuex

      const newUserMessage = {
        id: Date.now(), // Simple unique ID for demonstration (consider a more robust method)
        sender: 'user', // Update this as needed, e.g., dynamically determine the sender
        senderId: this.user.sub,
        text: this.message,
        timestamp: new Date().toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        }), // Example timestamp format
      };
      this.$emit('scroll-down');
      await this.saveMessage(newUserMessage);
      this.$emit('send-message', newUserMessage);

      const privateSession = this.$store.state.chatSettings.private_session;
      let redisUrl;

      if (privateSession && this.user.apps && this.user.apps.upstash_redis_private) {
        redisUrl = this.user.apps.upstash_redis_private.url;
      } else if (!privateSession && this.selectedExpert && this.selectedExpert.attributes.apps && this.selectedExpert.attributes.apps.upstash_redis_private) {
        redisUrl = this.selectedExpert.attributes.apps.upstash_redis_private.url;
      } else {
        //display the user that this is a public session
        redisUrl = "redis://node136679-b-bot.appengine.flow.ch:11000";
      }

      const expertIds = this.selectedExpert.attributes.experts.data.map(expert => expert.id);
      console.log("this.selectedModel",this.selectedModel)
      // Filter the team members from the experts
      const filteredExperts = this.experts.filter(expert => expertIds.includes(expert.id));
      console.log(filteredExperts)
      const payload = {
        id: this.selectedExpert.id,
        s_id: this.user.id,
        user_id: this.user.sub,
        input: this.message,
        provider: this.selectedModel.provider,
        temperature: this.$store.state.chatSettings.temperature,
        top_p: this.$store.state.chatSettings.top_p,
        character_prompt: this.selectedExpert.attributes.system_message,
        ability_prompt: this.selectedAbility && this.selectedAbility.attributes ? this.selectedAbility.attributes.text || '' : '',
        instructions: this.$store.state.chatSettings.instructions,
        //this will be deprecated soon
        model: this.selectedModel.value ? this.selectedModel.value : this.selectedModel.attributes.identifier,
        multiplier: this.selectedModel.multiplier,
        redis_url: redisUrl,
        session_id: "session-" + this.user.sub + this.currentChat,
        conversation_history: [],
        team: filteredExperts.map(expert => ({
          id: expert.id,
          ...expert.attributes
        })),
        apps: privateSession ? this.user.hub_user_metadata.apps : this.selectedExpert.attributes.apps,
        tool_config: {
          tavily_max_results: 5
        },
        tool_activation: this.toolActivation,
        document_urls: this.chatDocumentUrls,
      };


      try {
        const response = await fetch( VUE_APP_MAIN_API_URL + '/api/v0/invoke', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          this.$store.dispatch('setIsLoading', false);
          // Error indication Server down let the user know
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();

        const newAIMessage = {
          id: Date.now(), // Simple unique ID for demonstration (consider a more robust method)
          sender: 'assistant', // Update this as needed, e.g., dynamically determine the sender
          senderId: this.selectedExpert.id,
          text: responseData.output.replaceAll("\\(", "$")//
              .replaceAll("\\)", "$")//
              .replaceAll("\\[", "$$")//
              .replaceAll("\\]", "$$"),
          thoughts: responseData.intermediate_steps,
          timestamp: new Date().toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
          }),
        };


        this.$emit('scroll-down');

        await this.saveMessage(newAIMessage);
        //this.handleInput();
        this.setSelectedTemplateText('');
        this.message = ''; // Clear message input after sending
        this.$emit('send-message', newAIMessage);
        this.$refs.messageInput.resetText(); // Call resetText on MaterialTextarea component
        this.$store.dispatch('setIsLoading', false);
        this.$store.dispatch('setIsErrorInRequest', false);
      } catch (error) {
        this.$store.dispatch('setIsLoading', false);
        // Error indication something is wrong with the Request let the user know and correct the input
        this.$store.dispatch('setIsErrorInRequest', true);

        console.error("Error sending message:", error);
      }


    },
    handleInput(event) {
      if (event.target && typeof event.target.value !== 'undefined') {
        this.message = event.target.value;
      } else {
        this.message = ''; // or any other fallback action
      }
    },
    handleKeydown(event) {
      if (event.key === 'Enter' && event.shiftKey) {
        event.preventDefault();
        this.sendMessage();
      }
    },
  },
};
</script>

<style scoped>
.chat-input {
  display: flex;
  align-items: start; /* Keep items aligned to the bottom */
  gap: 0; /* Space between elements */
  padding: 0; /* Space inside the border */
  border-radius: 8px; /* Optional: Adds rounded corners */
  min-height: 33px; /* Adjust as needed */
}

.action-btn{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  position: sticky;
}

.h-50px{
  height: 50px;
}

/* Ensuring the textarea can grow in height while typing */
textarea.form-control {
  font-size: 16px;
  border: none; /* Remove the border */
  height: auto; /* Auto-adjust height */
  overflow-y: hidden; /* Hide vertical scrollbar */
}
.message-input{
  border:none;
}

/* Adjust the send button position */
.material-button {
  white-space: nowrap; /* Ensure button text doesn't wrap */
}

.chat-input .material-textarea textarea.form-control {
  border: none !important; /* Remove the border */
}
#message {
  border: none !important; /* Remove the border */
  border-radius: 0 !important; /* Remove the border radius */
  border-bottom: 1px solid #ccc !important; /* Add a bottom border */
  padding: 10px 0 !important; /* Add padding to the bottom */
  margin-bottom: 0 !important; /* Remove the margin */
  box-shadow: none !important; /* Remove the shadow */
  resize: none !important; /* Disable resizing */
  height: auto !important; /* Auto-adjust height */
  overflow-y: hidden !important; /* Hide vertical scrollbar */
}
</style>
