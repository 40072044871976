<template>
  <div class="container mt-5">
    <div class="d-flex justify-content-center align-items-center mb-3">
      <img
          class="w-25 me-3 mb-0 backdrop"
          src="@/assets/img/illustrations/link-dynamic-gradient.png"
          alt="logo"
      />
      <h2>
        Appstore
        <DocumentationLink
            popoverTitle="Appstore"
            class="z-index-5 documentation-link"
            description="Learn more about Apps and how to add them"
            docLink="https://docs.b-bot.space/essentials/apps"
        />
      </h2>
    </div>

    <!-- Category Filter Buttons -->


    <div class="container mb-5 mt-4">
      <div class="row mt-4 p-3">
        <div class="col-12 mb-4 text-center">
          <button
              v-for="category in uniqueCategories"
              :key="category"
              :class="'btn ' + (selectedCategory === category ? 'btn-primary' : 'btn-outline-primary' ) + ' me-2 mb-2'"
              @click="filterApps(category)"
          >
            {{ category }}
          </button>
          <button
              class="btn btn-outline-secondary mb-2"
              @click="filterApps('all')"
          >
            All
          </button>
        </div>
        <div v-for="app in filteredApps" :key="app.id" class="col-md-3 mb-3">
          <div class="card app-card">
            <div class="card-body">
              <img class="w-100 me-3 mb-0" :src="app.logo" alt="logo" />

              <h5 class="card-title">{{ app.name }}</h5>
              <p class="card-text">{{ app.description }}</p>
            </div>
            <div class="d-flex justify-content-around">
              <button
                  v-if="app.connectable && !app.connected"
                  class="btn btn-primary mb-0 w-100"
                  @click="selectApp(app)"
              >Connect</button>
              <button
                  v-if="app.connectable && app.connected"
                  class="btn btn-primary mb-0 w-100"
                  @click="selectApp(app)"
              >Config</button>
              <a
                  v-if="!app.connectable"
                  class="btn btn-primary mb-0 w-100"
                  :href="app.href"
              >More Information</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for selected app -->
    <VueFinalModal
        v-model="showModal"
        classes="modal-container"
        class="modal-container overflow-scroll"
        content-class="modal-content"
        :hide-overlay="true"
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
    >
      <div v-if="selectedApp" class="mt-4 mb-4 card p-3">
        <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top p-3">
          <h5 class="modal-title text-white">Settings for {{ selectedApp.name }}</h5>

          <button type="button" class="close-btn btn text-white" @click="closeModal">
            <i class="material-icons-round opacity-10 fs-5">close</i>
          </button>
        </div>
        <DynamicForm
            :showExpertChoose="true"
            v-if="selectedApp.identifier !== 'openai_assistant'"
            :settings="selectedApp.settings"
            :descriptions="selectedApp.descriptions"
            :validations="selectedApp.validations"
            @update="updateFormData"
            @form-valid="handleFormValid"
        />

        <OpenAIAssistant :showExpertChoose="true" v-if="selectedApp.identifier === 'openai_assistant'" @close="closeModal" @update="updateFormData" @form-valid="handleFormValid"/>

        <button @click="connectApp" :disabled="!isFormValid" class="btn btn-success mt-2">Save Settings</button>
      </div>
    </VueFinalModal>
  </div>
</template>


<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import DynamicForm from "@/views/components/DynamicForm.vue";
import OpenAIAssistant from "@/views/components/OpenAIAssistant.vue";
import {VueFinalModal} from "vue-final-modal";
import DocumentationLink from "./components/DocumentationLink.vue";
const { VUE_APP_DATACENTER_URL } = process.env;

export default {
  data() {
    return {
      selectedApp: null,
      showModal: false,
      formData: {},
      isFormValid: false,
      selectedCategory: 'all',
    };
  },
  computed:{
    ...mapState(['experts', 'selectedExpert', 'apps']),
    filteredApps() {
      if (this.selectedCategory === 'all') {
        return this.apps;
      }
      return this.apps.filter(app => app.category === this.selectedCategory);
    },
    // Computed property for unique categories
    uniqueCategories() {
      const categories = this.apps.map(app => app.category);
      return [...new Set(categories)]; // Get unique categories
    }
  },
  methods: {
    ...mapActions(['updateUserMetadataApp', 'fetchExperts']),
    ...mapMutations(['showEveryDisplay']),
    connectApp() {
      let key = this.selectedApp.identifier;
      const value = this.formData;

      if(this.selectedApp.identifier === 'openai_assistant'){
        key = this.selectedApp.identifier + '_' + this.formData.assistant_id;
      }


      // Include database_type in the payload
      const payload = {
        ...value,
        database_type: this.selectedApp.database_type,
      };

      if (this.formData?.private === true) {
        this.$store.dispatch('updateUserMetadataApp', { key, value: payload })
            .then(() => {
            })
            .catch((error) => {
              console.error('Error updating metadata:', error);
            });
      } else {
        this.handleSubmit(key, payload);
      }
      this.selectedApp = null;
      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
    },
    handleFormValid(isValid) {
      this.isFormValid = isValid;
    },
    updateFormData(newFormData) {
      this.formData = {};
      this.formData = newFormData;
    },
    filterApps(category) {
      this.selectedCategory = category; // Update selected category
    },
    selectApp(app) {
      this.selectedApp = app;
      this.formData = app.settings;
      this.showModal = true;
    },
    getCookie(name) {
      let value = `; ${document.cookie}`;
      let parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    async handleSubmit(key, payload) {
      const url = this.selectedExpert.id
          ? `${VUE_APP_DATACENTER_URL}/api/experts/${this.selectedExpert.id}` // PUT to update existing expert
          : `${VUE_APP_DATACENTER_URL}/api/experts`; // POST to create new expert

      const method = this.selectedExpert.id ? 'PUT' : 'POST';

      const accessToken = this.getCookie('datacenterAccessToken');

      try {
        const appIdentifier = key;
        const currentSettings = this.selectedExpert.attributes.apps || {};
        const updatedSettings = {
          ...currentSettings,
          [appIdentifier]: payload,
        };
        const response = await fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            data: {
              apps: updatedSettings,
            },
          }),
        });

        if (!response.ok) {
          throw new Error(`Failed to ${this.selectedExpert.id ? 'update' : 'create'} expert: ${response.statusText}`);
        }

        await response.json();

        setTimeout(() => {
          this.fetchExperts();
        }, 1000);
        this.showModal = false;

      } catch (error) {
        console.error('Error submitting expert data:', error);
        // Handle error, e.g., showing error message to the user
      }
      this.showModal = false;
    },
  },
  mounted() {
    this.showEveryDisplay();
  },
  components:{
    DocumentationLink,
    VueFinalModal,
    DynamicForm,
    OpenAIAssistant
  }
};
</script>

<style scoped>
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-card {
  width: 90%;
  max-width: 600px;
  margin: auto;
  overflow: hidden; /* Prevents content from overflowing the modal */
}

.modal-fullscreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.modal-scrollable-content {
  padding: 15px;
  overflow-y: auto; /* Enables scrolling for the content area */
  overflow-x: hidden;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.card-header-top{
  border-radius: 0 0 0.75rem 0.75rem !important;
}

.card-body, .card-footer {
  padding: 1rem;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.mr-button{
  margin-right: 1rem;
}

.business-card {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-picture {
  margin-right: 15px;
  border-radius: 50%;
}

.card-title {
  margin: 0;
  color: #333333;
  font-weight: bold;
}



.form-control::placeholder {
  color: #999999;
}

.navigation-buttons-container {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.navigation-buttons {
  pointer-events: auto;
  width: 100%;
  background: white;
}

/* Slide-fade transition for entering and leaving */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* leave-to for <= Vue 2.1.8 */ {
  transform: translateX(-100%);
  opacity: 0;
}

/* Backward transition */
.slide-fade-reverse-enter-active, .slide-fade-reverse-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-reverse-enter, .slide-fade-reverse-leave-to /* leave-to for Vue 2.1.8 and below */ {
  transform: translateX(100%);
  opacity: 0;
}
.icon-steps{
  position: absolute;
  top: 0.7rem;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  overflow-y: scroll !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  padding: .5rem 1rem;
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.steps-indicator{
  margin-bottom: 2rem;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}

.dark-version .vfm--overlay {
  background: black;
}

.dark-version .vfm--content {
  background: black;
}
.app-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-height: 300px; /* Set a maximum height for the cards */
  box-sizing: border-box;
  margin-bottom: 20px; /* Adds space between cards */
}

.card-body {
  flex-grow: 1;
  overflow: hidden; /* Prevents content overflow */
}

.card-text {
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis; /* Adds ellipsis if text overflows */
}

.card img {
  max-height: 100px; /* Restricts the image size */
  object-fit: contain;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.25rem;
  margin-top: 1rem;
  font-weight: bold;
}

.btn {
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .app-card {
    max-height: none; /* Remove max height on smaller screens */
    height: auto; /* Allow cards to adjust their height based on content */
  }

  .card img {
    max-height: 80px; /* Slightly smaller images on mobile */
  }

  .card-title {
    font-size: 1rem; /* Adjust title size on mobile */
    margin-top: 1rem;

  }
}

@media (max-width: 576px) {
  .app-card {
    max-height: none;
    height: auto;
    width: 100%; /* Ensure full width for cards on very small screens */
  }

  .card img {
    max-height: 60px; /* Even smaller images on very small screens */
  }

  .card-title {
    font-size: 0.875rem; /* Further adjust title size */
    margin-top: 1rem;

  }
}
.btn {
  margin-top: 10px;
}

.backdrop{
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
.documentation-link {
  display: inline; /* Ensure the icon appears inline */
  vertical-align: middle; /* Align vertically with the text */
  margin-left: 0px; /* Space between title and icon */
}
</style>
