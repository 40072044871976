<template>
  <div>
    <h3>Abilities</h3>
    <div v-if="abilities.length > 0">
      <ul class="list-group">
        <li v-for="ability in abilities" :key="ability.id" class="list-group-item d-flex justify-content-center mb-0">
          <button @click="$emit('ability-selected', ability)" class="btn btn-primary w-100 mb-0">
            {{ ability.attributes.text }}
          </button>
        </li>
      </ul>
    </div>
    <div v-else>
      <p>Keine Fähigkeiten verfügbar.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExpertAbilities',
  props: {
    abilities: Array,
  },
};
</script>

<style scoped>
.list-group {
  padding-left: 0;
  list-style: none;
  margin-top: 20px;
}

.list-group-item {
  margin-bottom: 10px;
}
</style>
