<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Settings</h3>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #default>
      <div class="card-header bg-gradient-primary d-flex justify-content-start shadow-primary card-header-top">
        <DocumentationLink
            popoverTitle="Chat Settings"
            class="z-index-5"
            description="Learn more about Chat Settings."
            docLink="https://docs.b-bot.space/essentials/chat#the-settings-window"
        />
        <h5 class="modal-title text-white mx-5">Settings</h5>
      </div>
      <div class="container pt-5 pb-5">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <h4>Temperature: {{ localTemperature }}</h4>
            <input type="range" class="form-range" min="0" max="1" step="0.01" v-model="localTemperature" @input="updateTemperature">
          </div>
          <div class="col-md-12">
            <h4>Top P: {{ localTopP }}</h4>
            <input type="range" class="form-range" min="0" max="1" step="0.01" v-model="localTopP" @input="updateTopP">
          </div>

          <div class="col-md-12 mt-3">
            <h4>Additional instructions:</h4>
            <material-textarea v-model="localInstructions" class="form-control mb-2" id="instructions-textarea" />
          </div>
          <div class="col-md-12 mt-3">
            <div class="d-flex align-items-center">
              <span class="mr-2">Session is {{ localPrivateSession ? 'private' : 'not private' }}</span>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div class="d-flex align-items-center">
              <material-switch id="toggle" name="Toggle" v-model="localPrivateSession">
                Private Session
              </material-switch>
            </div>
          </div>
        </div>
      </div>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
      <button @click="closeModal" class="btn btn-success w-100">
        <i class="material-icons-round opacity-10 fs-5">save</i>
      </button>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import { mapState, mapMutations } from 'vuex'
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialTextarea from "../../components/MaterialTextarea.vue";
import DocumentationLink from "./DocumentationLink.vue";
export default {
  components: {
    DocumentationLink,
    MaterialTextarea,
    VueFinalModal,
    MaterialSwitch,
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapState(['selectedExpert','chatSettings']),
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    localTemperature: {
      get() {
        return this.chatSettings.temperature;
      },
      set(value) {
        this.updateChatSettings({ setting: 'temperature', value });
      }
    },
    localInstructions: {
      get() {
        return this.chatSettings.instructions;
      },
      set(value) {
        this.updateChatSettings({ setting: 'instructions', value });
      }
    },
    localTopP: {
      get() {
        return this.chatSettings.top_p;
      },
      set(value) {
        this.updateChatSettings({ setting: 'top_p', value });
      },
    },
    localPrivateSession: {
      get() {
        return this.chatSettings.private_session;
      },
      set(value) {
        this.updateChatSettings({ setting: 'private_session', value });
      }
    },
  },
  methods: {
    ...mapMutations(['setSelectedModel', 'updateChatSettings']),
    closeModal() {
      this.isModalOpen = false;
    },
    updateTemperature() {
      this.$store.commit('updateChatSettings', { setting: 'temperature', value: this.localTemperature });
    },
    updateTopP() {
      this.$store.commit('updateChatSettings', { setting: 'top_p', value: this.localTopP });
    },
    updatePrivateSession() {
      this.$store.commit('updateChatSettings', { setting: 'temperature', value: this.localTemperature });
    },
  },
};
</script>

<style scoped>

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  border-radius: .75rem .75rem .75rem .75rem !important;
}

</style>
