import { createRouter, createWebHistory } from "vue-router";
import { authService } from '@/auth/authService'; // Adjust the path as necessary
import Chat from "../views/Chat.vue";
import Training from "../views/Training.vue";
import Dashboard from "../views/Dashboard.vue";
import ExpertsTable from "../views/ExpertsTable.vue";
import RTL from "../views/Rtl.vue";
import Apps from "../views/Apps.vue";
import Profile from "../views/Profile.vue";
import SignIn from "../views/SignIn.vue";
import News from "../views/News.vue";
import Home from "../views/Home.vue";
import store from '../store'; // Assuming you have Vuex store setup
import {firebaseService} from "../../services/firebaseService";
import { jwtDecode } from 'jwt-decode';


const routes = [
  {
    path: "/",
    name: "/",
    meta: { requiresAuth: false },
    component: Home,
    redirect: "/news",
  },
  {
    path: "/home",
    name: "/home",
    meta: { requiresAuth: false },
    component: Home,
  },
  {
    path: "/datacenter-token",
    name: "/datacenter-token",
    meta: { requiresAuth: false },
    component: Home,
    beforeEnter() {
      const redirectUrl = `${process.env.VUE_APP_DATACENTER_URL}/api/connect/auth0?redirect_uri=${process.env.VUE_APP_BASE_URL}/api-token`;
      window.location.replace(redirectUrl);
    }
  },
  {
    path: "/api-token",
    name: "/api-token",
    meta: { requiresAuth: false },
    component: Home,
    beforeEnter() {
      const redirectUrl = `${process.env.VUE_APP_MAIN_API_URL}/api/connect/auth0?redirect_uri=${process.env.VUE_APP_BASE_URL}/news`;
      window.location.replace(redirectUrl); // Redirect to obtain token
    }
  },
  {
    path: "/realtime-database-token",
    name: "/realtime-database-token",
    meta: { requiresAuth: false },
    component: Home,
    beforeEnter() {
      const redirectUrl = `${process.env.VUE_APP_BASE_URL}/.netlify/functions/realtime-database?redirect_uri=${process.env.VUE_APP_BASE_URL}/news`;
      window.location.replace(redirectUrl); // Redirect to obtain token
    }
  },
  {
    path: "/news",
    name: "News",
    meta: { requiresAuth: false },
    component: News,
  },
  {
    path: "/chat",
    name: "Chat",
    meta: { requiresAuth: true },
    component: Chat,
  },
  {
    path: "/training",
    name: "Training",
    meta: { requiresAuth: true },
    component: Training,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: Dashboard,
  },
  {
    path: "/experts",
    name: "Experts",
    meta: { requiresAuth: true },
    component: ExpertsTable,
  },
  {
    path: "/billing",
    name: "Billing",
    meta: { requiresAuth: true },
    beforeEnter() {
      window.location.replace("https://pay.b-bot.ch/p/login/3cs29k6SSeIS7bacMM")
    }
  },
  {
    path: "/rtl-page",
    name: "RTL",
    meta: { requiresAuth: true },
    component: RTL,
  },
  {
    path: "/apps",
    name: "Apps",
    meta: { requiresAuth: true },
    component: Apps,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { requiresAuth: true },
    component: Profile,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    meta: { requiresAuth: false },
    component: SignIn,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

// Function to check if a token is expired
function isTokenExpired(token) {
  if (!token) {
    console.error("Token is empty or undefined");
    return true; // Consider it expired if no token is provided
  }

  try {
    const decoded = jwtDecode(token); // Ensure you have jwt-decode imported
    const currentTime = Date.now() / 1000; // JWT expiration is in seconds
    const expirationTime = decoded.exp; // Token expiration time from the decoded token

    // Log the expiration date and time in a readable format
    const expirationDate = new Date(expirationTime * 1000); // Convert seconds to milliseconds
    console.log("Token expiration date and time:", expirationDate.toLocaleString());

    return expirationTime < currentTime; // Returns true if expired
  } catch (error) {
    console.error("Error decoding token:", error);
    return true; // If decoding fails, consider the token expired
  }
}


async function fetchToken() {
  const token = getCookie('firebaseToken');
  console.log("Fetched Token:", token);

  if (!isTokenExpired(token)) {
    console.log("Token is still valid, no need to fetch a new one.");
    return;
  }

  console.log("TOKEN INVALID! Fetching new token...");

  try {
    await fetch(`${process.env.VUE_APP_BASE_URL}/.netlify/functions/realtime-database?redirect_uri=http://localhost:8888/news`, {
      method: 'GET',
    });

    console.log("Token fetch request sent successfully");
  } catch (error) {
    console.error("Error fetching token:", error);
    throw error;
  }
}

// Vue Router beforeEach guard
router.beforeEach(async (to, from, next) => {
  await store.dispatch('setIsBackgroundLoading', true); // Show loading screen

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  await authService.initClient(); // Initialize authentication service
  await store.dispatch('setIsBackgroundLoading', false); // Hide loading screen

  if (requiresAuth) {
    const isAuthenticated = store.state.isAuthenticated;

    if (isAuthenticated) {
      const token = getCookie('firebaseToken'); // Get the token to check
      if (isTokenExpired(token)) { // Check if the token is expired
        try {
          await fetchToken(); // Fetch a new token if expired
          await firebaseService.signInWithCustomTokenServe(); // Use the new token to sign in
        } catch (error) {
          console.error("Error in token retrieval:", error);
          return next('/sign-in'); // Redirect to sign-in on error
        }
      } else {
        console.log("TOKEN still VALID:", token);
      }
      next(); // Proceed to the next route
    } else {
      next('/sign-in'); // Redirect to sign-in if not authenticated
    }
  } else {
    next(); // Proceed to the next route if no authentication is required
  }
});
router.afterEach(() => {
  store.dispatch('setIsBackgroundLoading', false); // Hide loading screen after navigation is complete
});

export default router;
