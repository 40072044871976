<template :key="key">
  <div

      :class="'chat-message d-flex align-items-start position-relative' +  'is-selected ' + isChecked + ' background_' + role"
  >
    <!-- Message Content -->
    <div class="message-content ms-2">
      <div class="message-header">
        <div class="mr-5" v-if="isSelectionMode">
          <material-switch @change="selectMessage" label-class="mb-0 text-body text-truncate w-80" type="checkbox" v-model="isChecked" :id="id" :name="id"></material-switch>
        </div>
        <MaterialAvatar
            v-else
            :img="profilePictureUrl"
            alt="Profile Picture"
            size="small"
            shadow="regular"
            :circular="true"
            borderRadius="50%"
            @error="imageError"

        />
        <span class="sender">{{ senderName }}</span>
        <span class="timestamp">{{ formattedTimestamp }}</span>
        <i class="material-icons-round fs-5 cursor-pointer" v-if="hasThoughts()" @click.prevent="openThoughtsModal">psychology</i>
      </div>
      <v-md-preview class="message-text" v-if="role === 'assistant' && !editing" :text="editableText"></v-md-preview>
      <material-textarea
          v-if="role === 'assistant' && editing"
          mode="edit"
          height="100px"
          left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code"
          right-toolbar="fullscreen"
          :id="key"
          class="form-control editor"
          v-model="editableText"
      ></material-textarea>
      <p class="message-text" v-if="role === 'user' && !editing">{{editableText}}</p>
      <material-textarea
          v-if="role === 'user' && editing"
          mode="edit"
          height="100px"
          left-toolbar="undo redo clear"
          right-toolbar="fullscreen"
          :id="key"
          class="form-control editor"
          v-model="editableText"
      ></material-textarea>
      <material-button
          class="btn-save-message mt-0 mb-0 w-100"
          variant="gradient"
          color="success"
          size="md"
          v-if="editing"
          @click="editContent"
      >
        <i class="material-icons-round opacity-10 text-white">save</i>
      </material-button>
    </div>
    <!-- Dropdown Toggle -->
    <button class="show-more" type="button" data-bs-toggle="dropdown" aria-expanded="false" :id="`dropdownMenuButton-${id}`">
      <i class="material-icons-round opacity-10 fs-5">more_vert</i>
    </button>
    <!-- Dropdown Menu -->
    <ul class="dropdown-menu" :aria-labelledby="`dropdownMenuButton-${id}`">
      <li><a class="dropdown-item" href="#" @click.prevent="copyContent">Copy MD</a></li>
      <li><a class="dropdown-item" href="#" @click.prevent="copyContentRichText">Copy Text</a></li>
      <li v-if="possibleToEdit"><a class="dropdown-item" href="#" @click.prevent="editContent">Edit</a></li>
      <li v-if="qaMarkingActivated"><a class="dropdown-item" href="#" @click.prevent="emitStartQAMarking">Start QA Marking</a></li>
      <li><a class="dropdown-item" href="#" @click.prevent="openThoughtsModal" v-if="hasThoughts()">Thoughts</a></li>

      <li v-if="isAbleToOpenActionModel">
        <a class="dropdown-item" v-if="role === 'ai' || role === 'assistant'" href="#" @click.prevent="$emit('open-action-modal')">Add Action</a>
      </li>
      <li v-if="isAbleToRemoveMessage">
        <a class="dropdown-item" href="#" @click.prevent="$emit('remove-message')">Remove</a>
      </li>
      <li v-if="isAbleToggleRole">
        <a class="dropdown-item" href="#" @click.prevent="$emit('toggle-role')">Toggle Role</a>
      </li>
      <li v-if="isAbleToMoveMessages">
        <a class="dropdown-item" href="#" @click.prevent="$emit('move-message-up')">Move Up</a>
      </li>
      <li v-if="isAbleToMoveMessages">
        <a class="dropdown-item" href="#" @click.prevent="$emit('move-message-down')">Move Down</a>
      </li>
    </ul>
  </div>
  <VueFinalModal
      v-model="isThoughtsModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <!-- Modal Content -->
    <template #default>
      <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top">
        <h5 class="modal-title text-white">Thoughts</h5>
      </div>
      <div class="accordion mt-3" id="thoughtsAccordion">

        <div v-for="(thought, index) in thoughts" :key="index" class="accordion-item">
          <h2 class="accordion-header" :id="'heading' + index">
            <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#collapse' + index"
                :aria-expanded="index === 0 ? 'true' : 'false'"
                :aria-controls="'collapse' + index"
            >
              Thought {{ index + 1 }}
            </button>
          </h2>
          <div
              :id="'collapse' + index"
              class="accordion-collapse collapse"
              :class="{ show: index === 0 }"
              :aria-labelledby="'heading' + index"
              data-bs-parent="#thoughtsAccordion"
          >
            <div class="accordion-body">
              <!-- Check if it's a Consultation -->
              <div v-if="thought && thought[0] && thought[0].type && thought[0].tool.startsWith('Consultation_')">
                <!-- Display Invocation -->

                <div class="mb-2 mt-2" v-html="'Invoking: `' + thought[0].tool"></div>

                <MaterialAvatar
                    :img="profilePictureUrl || defaultAvatarUrl"
                    alt="Profile Picture"
                    size="small"
                    shadow="regular"
                    :circular="true"
                    borderRadius="50%"
                    @error="imageError"

                />
                <!-- Display Input -->
                <div v-if="thought[1].input">
                  <h5>Input:</h5>
                  <p>{{ thought[1].input }}</p>
                </div>

                <!-- Display Output -->
                <div v-if="thought[1].output">
                  <h5>Output:</h5>
                  <div v-html="thought[1].output"></div>
                </div>

                <!-- Display Intermediate Steps -->
                <div v-if="thought[1].intermediate_steps && thought[1].intermediate_steps.length > 0">
                  <h5>Intermediate Steps:</h5>
                  <ul>
                    <li v-for="(step, stepIndex) in thought[1].intermediate_steps" :key="stepIndex">
                      <p>{{ step }}</p>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p>No intermediate steps available.</p>
                </div>
              </div>

              <!-- General Content Rendering -->
              <div v-if="thought && !thought[0] && !thought.tool.startsWith('Consultation_')">
                <div v-if="thought.tool_output && Array.isArray(thought.tool_output)">
                  <div v-for="(output, index) in thought.tool_output" :key="index">
                    <div v-for="(value, key) in output" :key="key">
                      <!-- If the key is 'url', create a clickable link -->
                      <div v-if="key === 'url'">
                        <a :href="value" target="_blank" class="btn btn-primary" role="button">{{ value }}</a>
                      </div>
                      <!-- If the key is 'content', show the content as a paragraph -->
                      <div v-else-if="key === 'content'">
                        <p>{{ value }}</p>
                      </div>
                      <!-- For any other key-value pair, display it normally -->

                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <div v-html="thought[0]?.log"></div>
                <div v-if="thought[1] && Array.isArray(thought[1])">
                  <div>
                    <div v-for="(item, itemIndex) in thought[1]" :key="itemIndex">
                      <div v-for="(value, key) in item" :key="key">
                        <div v-if="key === 'url'">
                          <a :href="value" target="_blank" class="btn btn-primary" role="button">{{ value }}</a>
                        </div>
                        <div v-else-if="key === 'content'">
                          <p>{{ value }}</p>
                        </div>
                        <div v-else>
                          <!-- Handle other attributes if needed -->
                          <strong>{{ key }}:</strong> {{ value }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ thought[1] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button @click="closeThoughtsModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

  </VueFinalModal>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import {VueFinalModal} from "vue-final-modal";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "../../components/MaterialButton.vue";
import MaterialTextarea from "../../components/MaterialTextarea.vue";
import VueMarkdownEditor, { xss } from '@kangc/v-md-editor';

export default {
  name: 'ChatMessage',
  components: {
    MaterialTextarea,
    MaterialButton,
    MaterialSwitch,
    VueFinalModal,
    MaterialAvatar,
  },
  props: {
    key: String,
    role: String,
    sender: String,
    senderName: String,
    senderId: String,
    text: String,
    timestamp: String,
    thoughts: Array,
    profilePictureUrl: String,
    id: String,
    isSelected: Boolean,
    isSelectionMode: Boolean, // Add this line
    qaMarkingActivated: {
      type: Boolean,
      default: true,
    },
    possibleToEdit: {
      type: Boolean,
      default: false,
    },
    isAbleToOpenActionModel: {
      type: Boolean,
      default: false,
    },
    isAbleToRemoveMessage: {
      type: Boolean,
      default: false,
    },
    isAbleToggleRole: {
      type: Boolean,
      default: false,
    },
    isAbleToMoveMessages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: this.isSelected,
      isThoughtsModalOpen: false,
      defaultAvatarUrl: 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg',
      editing: false,
      md: null
    };
  },
  computed: {
    editableText: {
      get() {
        return this.text;
      },
      set(value) {
        this.$emit('update:text', value);
      }
    },
    messageObject() {
      return {
        id: this.id,
        sender: this.sender,
        text: this.text,
        timestamp: this.timestamp,
        thoughts: this.thoughts,
        profilePictureUrl: this.profilePictureUrl,
        isSelected: this.isSelected
      };
  },
    formattedTimestamp() {
      if (!isNaN(new Date(this.timestamp).getTime())) {
        const date = new Date(this.timestamp);

        // Get month names array
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];

        // Extract date components
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        // Format hours, minutes, and seconds
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Determine AM/PM and adjust hour format
        const period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

        return `${month} ${day}, ${year} at ${hours}:${minutes}:${seconds} ${period}`;
      } else {
        // If timestamp is already formatted, return as is
        return this.timestamp;
      }
    }
  },
  methods: {
    editContent(){
      this.editing = !this.editing;
      if (!this.editing) {
        this.$emit('update:text', this.editableText);
      }
    },
    copyContent() {
      navigator.clipboard.writeText(this.text).then(() => {
        alert('Content copied to clipboard');
      }, (err) => {
        console.error('Could not copy text: ', err);
      });
    },
    /* eslint-disable no-undef */
    copyContentRichText() {
      // Convert Markdown to HTML with Mermaid diagrams
      const htmlToCopy = xss.process(VueMarkdownEditor.vMdParser.themeConfig.markdownParser.render(this.text));
      // Create a Blob with the HTML content
      const blob = new Blob([htmlToCopy], { type: 'text/html' });

      // Create a ClipboardItem from the Blob
      const clipboardItem = new ClipboardItem({
        [blob.type]: blob
      });

      navigator.clipboard.write([clipboardItem]).then(() => {
        alert('Content copied to clipboard');
      }, (err) => {
        console.error('Could not copy text: ', err);
      });
    },
    /* eslint-enable no-undef */
    emitStartQAMarking() {
      // This will inform the parent component to enter selection mode and mark this message
      this.$emit('start-qa-marking', this.messageObject);
    },
    toggleSelection() {
      this.isChecked = !this.isChecked;
      this.selectMessage();
    },
    selectMessage() {
      this.$emit('select-message', this.messageObject);
    },
    handleClick() {
      if (this.isSelectionMode) {
        // In selection mode, toggle the selection
        this.toggleSelection();
      } else {
        // Otherwise, perform other click actions, if any
      }
    },
    hasThoughts() {
      // Check if the message has thoughts
      // Replace `this.message` with the actual message data
      return this.thoughts && this.thoughts.length > 0;
    },
    openThoughtsModal() {
      this.isThoughtsModalOpen = true;
    },
    closeThoughtsModal() {
      this.isThoughtsModalOpen = false;
    },
    imageError(event) {
      // When an error occurs, replace the src with the fallback image
      event.target.src = this.defaultAvatarUrl;
    }
  },
  watch: {
    isSelected(newVal) {
      this.isChecked = newVal;
    },
    watch: {
      // Watch the prop for changes and update local text
      text(newVal) {
        this.editableText = newVal;
      }
    },
  },
};
</script>

<style scoped>
.chat-message {
  display: flex;
  align-items: flex-start; /* Align items at the start for messages */
  margin-bottom: 30px;
  border-radius: 1rem;
  padding-bottom: 10px;
  padding-top: 5px;
  color: #0a0a0a;
}

.chat-message i{
  color: #0a0a0a;
}

.dark-version .chat-message i{
  color: #ffffff !important;
}

.dark-version .chat-message{
  color: white !important;
}

.chat-message:hover {
  //color: #0a0a0a !important;
  //background: #f5f5f5;
}

.chat-message:hover .message-header {
  color: #0a0a0a !important;
  //background: #f5f5f5;
}
.chat-message:hover .message-header .timestamp {
  color: #0a0a0a !important;
  //background: #f5f5f5;
}

.dark-version .chat-message:hover .message-header {
  color: #ffffff !important;
  //background: #f5f5f5;
}
.dark-version .chat-message:hover .message-header .timestamp {
  color: #ffffff !important;
  //background: #f5f5f5;
}



.background_user {
  align-self: flex-end;
  width: 80%;
  background: #f1f1f1;
  text-align: right;
}

.dark-version .background_user {
  background: #292929;
  color: white;
}

.dark-version .chat-message:hover {
  background: #292929;
  color: white;
}

.message-content {
  width: 100%;
  border-radius: 8px;
  margin-left: 10px /* Space between avatar and message content */

}

.message-header {
  color: transparent;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.message-header .timestamp {
  color: transparent;
}

.sender {
  font-weight: bold;
}

.timestamp {
  color: #999;
  font-size: 0.8rem;
}

.message-text {
  word-break: break-word;
}
.avatar {
  width: 30px;
  height: 30px;
}
.show-more{
  background: transparent;
  outline: none;
  border: none;
  z-index: 0;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  overflow-y: scroll !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.github-markdown-body div{
  z-index: 0;
  border-radius: 1rem !important;
}

.btn-save-message{
  border-radius: 0 0 1rem 1rem;
}
</style>
