<template>
  <div class="expert-cv container my-4">
    <div class="card mb-5">
      <div class="card-body text-center bg-gradient-success border-radius-lg shadow-success">
        <h2 class="cv-header card-title text-white">Expert CV</h2>
      </div>
    </div>

    <div class="card mb-4">
      <div class="row g-0">
        <div class="col-md-4">
          <img :src="expertData.attributes?.profile_picture?.data?.attributes?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'" alt="Profile Picture" class="rounded w-100">
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h3 class="card-title">Profile</h3>
            <p class="card-text"><strong>Name:</strong> {{ expertData.attributes.name }}</p>
            <p class="card-text"><strong>Description:</strong> {{ expertData.attributes.description }}</p>
            <p class="card-text"><strong>Profession:</strong> {{ expertData.attributes.profession }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-body">
        <h3 class="card-title">Abilities</h3>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" v-for="(ability, index) in expertData.attributes.abilities.data" :key="index">
            <strong>{{ ability.attributes.name }}</strong>: {{ ability.attributes.text }}
          </li>
        </ul>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Templates</h3>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" v-for="(template, index) in expertData.attributes.templates.data" :key="index">
            <strong>{{ template.attributes.template_name }}</strong>: {{ template.attributes.template_text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expertData: Object
  }
};
</script>

<style scoped>
.cv-header {
  color: #333;
}
</style>
