<template>
  <div class="container">
    <div class="d-flex justify-content-center align-items-center mb-3">
      <img
          class="w-25 me-3 mb-0 backdrop"
          src="@/assets/img/illustrations/chat-text-dynamic-gradient.png"
          alt="logo"
      />
      <h2>Chat with Experts</h2>
      <DocumentationLink
          popoverTitle="How To Chat"
          class="float-end position-sticky z-index-5"
          description="Learn more about how to use the Chat here."
          docLink="https://docs.b-bot.space/essentials/chat"
      />
    </div>

    <div class="row">

      <div v-if="experts.length === 0" class="col-12 text-center">
        <p>No experts available at the moment.</p>
        <button class="btn btn-primary" @click="redirectToExperts">Go to Experts</button>
      </div>
      <div v-else v-for="expert in experts" :key="expert.id" class="col-md-6 col-lg-4 mb-3">
        <div class="card shadow-sm">
          <div class="card-body text-center">
            <img :src="expert.attributes.profile_picture?.data?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'" class="rounded-circle mb-3" :alt="expert.attributes.name" style="width: 100px; height: 100px;" />
            <h5 class="card-title">{{ expert.attributes.name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ expert.attributes.profession }}</h6>
            <p class="card-text">{{ expert.attributes.function_name }}</p>
            <p class="card-text">{{ expert.attributes.function_description }}</p>
            <span class="badge bg-success">Online</span>
            <p class="card-text"><small class="text-muted">{{ formatDate(expert.attributes.createdAt) }}</small></p>

          </div>
          <div class="d-flex justify-content-around">
            <button class="btn btn-primary btn-link m-0 w-100" @click="handleExpertSelection(expert)">Chat</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DocumentationLink from "./DocumentationLink.vue";

export default {
  name: 'ExpertList',
  components: {
    DocumentationLink
  },
  computed: {
    ...mapState(['experts', 'color', 'userChats']),
  },
  methods: {
    ...mapMutations(['setSelectedExpert']),
    handleExpertSelection(expert) {
      this.setSelectedExpert(expert);
      this.$emit('expert-selected', expert); // Emitting the event to the parent
    },
    selectAbility(ability) {
      this.$store.dispatch('selectAbility', ability);
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    redirectToExperts() {
      // Redirect to the /experts route
      this.$router.push('/experts');
    },
  },
};
</script>


<style scoped>
.expert-list {
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 0;
  border-radius: 12px;
}

.expert-item {
  cursor: pointer;
  margin-bottom: 15px;
  padding-top: 15px;
}


.expert-info {
  text-align: center;
  padding-top: 15px;
}

.icon-size {
  font-size: 15px !important;
}

.border-cut{
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.active {
  background-color: #f0f0f0; /* Example background color for active items */
  /* Add other styling as needed */
}
.expert-item {
  cursor: pointer;
}

.expert-info {
  text-align: left;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-body {
  flex-grow: 1;
}

.d-flex {
  display: flex;
  align-items: stretch; /* Ensures that all items stretch to the same height */
}

.card img {
  height: 150px; /* Ensures images don't stretch the card */
  object-fit: contain;
}

.backdrop{
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}
</style>