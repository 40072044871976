<template>
  <div class="container mt-5">

    <h2>Manage OpenAI Assistants</h2>

    <material-input
        label="name"
        name="Name"
        size="lg"
        id="name"
        v-model="name"
        class=""
    />

    <label
        for="description" class="form-label m-0 mt-3">
      Content Description
    </label>
    <material-textarea v-model="description" class="form-control p-0"  id="description"/>
    <small class="form-text text-muted w-100 mb-3">Describe what can be found in the Documents you add to the assistant</small>

    <!-- Step 1: API Key Input -->
    <div v-if="!apiKeySubmitted" class="mb-3 mt-3">
      <material-input
          label="Enter API Key"
          name="name"
          size="lg"
          id="apiKeyInput"
          v-model="apiKey"
          class=""
      />

      <button @click="submitApiKey" class="btn btn-primary mt-2 ">Load Assistants</button>

    </div>
    <div v-if="apiKeySubmitted && !assistants" class="mt-4">
      <i
          class="fas fa-spinner text-secondary fa-spin"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="action.tooltip"
      ></i>
    </div>
    <!-- Step 2: List Existing Assistants -->
    <div v-if="apiKeySubmitted && assistants.length > 0" class="mt-4">
      <h3>Select an Existing Assistant</h3>


      <div class="row">
        <div
            v-for="assistant in assistants"
            :key="assistant.id"
            class="col-md-6 col-lg-4 mb-3"
        >
          <div :class="'card shadow-sm assistants ' + (selectedAssistant?.id === assistant.id ? ' active' : '')">
            <div class="card-body text-center">
              <h5 class="card-title">{{ assistant.name }}</h5>
              <p class="card-subtitle mb-2 text-muted">Instructions: {{ assistant.instructions }}</p>
              <p class="card-subtitle mb-2 text-muted">TopP: {{ assistant.top_p }}</p>
              <p class="card-subtitle mb-2 text-muted">Temperature: {{ assistant.temperature }}</p>

            </div>
            <div class="d-flex justify-content-around">
              <button class="btn btn-primary m-0 w-50 delete-border" @click="selectAssistant(assistant)">
                Choose
              </button>
              <!-- Edit Button -->
              <button class="btn btn-secondary m-0 w-50 edit-border" @click="editAssistant(assistant)">
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Button to Create a New Assistant -->
      <button @click="openCreateModal" class="btn btn-success mt-4">Create New Assistant</button>

      <material-switch id="toggle" name="Toggle" v-if="showExpertChoose" v-model="privateApp">
        Private
      </material-switch>

      <div v-if="!privateApp && showExpertChoose" class="mb-3 choose-expert">
        <p>Choose an expert to connect to:</p>
        <div v-if="experts.length === 0" class="text-center">
          <p>No experts available at the moment.</p>
          <button class="btn btn-primary" @click="redirectToExperts">Go to Experts</button>
        </div>
        <div v-else class="row">
          <div v-for="expert in experts" :key="expert.id" class="col-md-6 col-lg-4 mb-3">
            <div :class="'card shadow-sm' + (selectedExpert?.id === expert.id ? ' active' : '')">
              <div class="card-body text-center">
                <img :src="expert.attributes.profile_picture?.data?.attributes?.formats?.small?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'"
                     class="rounded-circle mb-3" :alt="expert.attributes.name" style="width: 100px; height: 100px;"/>
                <h5 class="card-title">{{ expert.attributes.name }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ expert.attributes.profession }}</h6>
              </div>
              <button class="btn btn-primary mb-0" @click="handleExpertSelection(expert)">Choose</button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Modal to Create or Edit Assistant -->
    <VueFinalModal
        v-model="showCreateModal"
        classes="modal-container"
        class="modal-container overflow-scroll"
        content-class="modal-content"
        :hide-overlay="true"
        overlay-transition="vfm-fade"
        content-transition="vfm-fade"
    >
      <div class="mt-4 mb-4 card p-3">
        <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top p-3">
          <h5 class="modal-title text-white">{{ isEditing ? 'Edit' : 'Create' }} Assistant</h5>

          <button type="button" class="close-btn btn text-white" @click="handleClick">
            <i class="material-icons-round opacity-10 fs-5">close</i>

          </button>
        </div>
        <div v-if="!showFileManagement">
          <div class="mb-3 mt-3">
            <material-input
                label="Assistant Name"
                name="name"
                size="lg"
                id="assistantName"
                v-model="newAssistant.name"
                class=""
            />


          </div>
          <div class="mb-3 mt-3">
            <label for="assistantDesc" class="form-label">Assistant Instructions</label>
            <material-textarea id="assistantDesc" v-model="newAssistant.instructions" class="form-control mb-2" />

          </div>
          <button @click="handleAssistant" class="btn btn-success">{{ isEditing ? 'Update' : 'Create' }} Assistant</button>
        </div>
        <!-- File Management Section -->
        <div v-if="showFileManagement" class="mt-4">
          <h4>Manage Files for {{ newAssistant.name }}</h4>
          <material-input
              name="name"
              size="lg"
              id="assistantName"
              type="file"
              class=""
              @change="handleFileUpload"
          />
          <button @click="addFileToVectorStore" class="btn btn-primary mt-2">Add File</button>

          <!-- List of Files -->
          <div class="list-group mt-3 mb-3">
            <div v-for="file in vectorStoreFiles" :key="file.id" class="list-group-item">
              <span>{{ file.name || file.id }}</span>
              <button @click="removeFileFromVectorStore(file.id)" class="btn btn-danger btn-sm  float-right position-absolute" style="right: 0;top: 0;">Remove</button>
            </div>
          </div>
        </div>
        <button class="btn btn-primary btn-sm me-2" @click="handleClick">Submit</button>

      </div>
    </VueFinalModal>
  </div>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import {mapMutations, mapState} from "vuex";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import loadingChat from "@/assets/img/illustrations/loadingChat.json";

export default {
  props:{
    settings: {
      type: Object,
      required: true,
    },
    showExpertChoose: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data() {
    return {
      animationData: loadingChat,
      apiKey: "",
      apiKeySubmitted: false,
      assistants: [],
      name: "",
      description: "",
      newAssistant: {
        name: "",
        instructions: "",
        assistant_id: "",
        model: "gpt-4",
        "tools": [{"type": "file_search"}],
      },
      selectedAssistant: null, // Add this for tracking the selected assistant
      showCreateModal: false,
      isEditing: false,
      showFileManagement: false,
      vectorStoreId: null,
      vectorStoreFiles: [],
      uploadedFile: null,
      privateApp: false,
    };
  },
  mounted() {
    if(this.settings){
      this.name = this.settings.name;
      this.description = this.settings.description;
      this.apiKey = this.settings.apiKey
      this.selectedAssistant = this.settings.assistant_id;
    }
  },
  computed: {
    ...mapState(['experts', 'selectedExpert']),
  },
  methods: {
    ...mapMutations(['setSelectedExpert']),

    handleExpertSelection(expert) {
      this.setSelectedExpert(expert);
    },
    redirectToExperts() {
      this.$router.push('/experts');
    },
    getInputType(key) {
      if (typeof this.settings[key] === 'boolean') {
        return 'checkbox';
      }
      if (key.toLowerCase().includes('url')) {
        return 'url';
      }
      if (key.toLowerCase().includes('apikey') || key.toLowerCase().includes('secret')) {
        return 'password';
      }
      return 'text';
    },
    handleClick() {
      this.showCreateModal = false;
      this.$emit('form-valid', true)
      this.$emit('update', {
        "private": this.privateApp,
        "tool_type": 'openai_asst',
        "apiKey": this.apiKey,
        "name": this.name,
        "description": this.description,
        "assistant_id": this.newAssistant.assistant_id
      });
    },
    // Submit the API key and fetch assistants
    async submitApiKey() {
      if (this.apiKey) {
        this.apiKeySubmitted = true;
        await this.fetchAssistants();
      }
    },
    // Fetch the list of assistants
    async fetchAssistants() {
      try {
        const response = await fetch("https://api.openai.com/v1/assistants", {
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
            "OpenAI-Beta": "assistants=v2",
          },
        });
        const data = await response.json();
        this.assistants = data.data;

      } catch (error) {
        console.error("Error fetching assistants:", error);
      }
    },
    // Select an assistant and load its details and vector store
    async selectAssistant(assistant) {
      this.$emit('form-valid', true)

      this.selectedAssistant = assistant; // Set the selected assistant
      // If the assistant has a vector store, load it (no modal opening needed)
      if (assistant.tool_resources.file_search.vector_store_ids.length) {
        this.vectorStoreId = assistant.tool_resources.file_search.vector_store_ids[0];
        await this.loadVectorStoreFiles();
        this.showFileManagement = true;
      }
      this.$emit('update', {
        "private": this.privateApp,
        "tool_type": 'openai_asst',
        "apiKey": this.apiKey,
        "name": this.name,
        "description": this.description,
        "assistant_id": assistant.id
      });
    },
    // Create or Update the assistant
    async handleAssistant() {
      if (!this.isEditing) {
        await this.createAssistant();
      } else {
        await this.updateAssistant();
      }
    },
    // Create new assistant and vector store
    async createAssistant() {
      try {
        this.selectedAssistant = null;
        this.showFileManagement = false;
        // Create vector store for the assistant
        const vectorStoreResponse = await fetch("https://api.openai.com/v1/vector_stores", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
          body: JSON.stringify({ name: `${this.newAssistant.name} Vector Store` }),
        });
        const vectorStore = await vectorStoreResponse.json();
        this.vectorStoreId = vectorStore.id;
        this.newAssistant = {
          name: this.newAssistant.name,
          instructions: this.newAssistant.instructions,
          model: "gpt-4o-mini",
          tool_resources:{"file_search": {"vector_store_ids": [vectorStore.id]}},
          "tools": [
            {
              "type": "file_search"
            }
          ],
          temperature: 0,
        };
        const response = await fetch("https://api.openai.com/v1/assistants", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
          body: JSON.stringify(this.newAssistant),
        });
        const assistant = await response.json();
        this.newAssistant.assistant_id = assistant.id;


        this.showFileManagement = true;
        this.$emit('update', {
          "private": this.privateApp,
          "tool_type": 'openai_asst',
          "apiKey": this.apiKey,
          "name": this.name,
          "description": this.description,
          "assistant_id": assistant.id
        });
        await this.fetchAssistants();
        this.selectedAssistant = assistant;
      } catch (error) {
        console.error("Error creating assistant or vector store:", error);
      }
    },
    // Update assistant details
    async updateAssistant() {
      try {
        const response = await fetch(`https://api.openai.com/v1/assistants/${this.newAssistant.assistant_id}`, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
          body: JSON.stringify(this.newAssistant),
        });
        await response.json();
        this.$emit('update', {
          "private": this.privateApp,
          "tool_type": 'openai_asst',
          "apiKey": this.apiKey,
          "name": this.name,
          "description": this.description,
          "assistant_id": this.newAssistant.assistant_id
        });
      } catch (error) {
        console.error("Error updating assistant:", error);
      }
    },
    // Load files from the vector store
    async loadVectorStoreFiles() {
      try {
        const response = await fetch(`https://api.openai.com/v1/vector_stores/${this.vectorStoreId}/files`, {
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
            "OpenAI-Beta": "assistants=v2",
          },
        });
        const data = await response.json();
        this.vectorStoreFiles = data.data.map(file => ({
          ...file,
          name: file.id, // Use file ID as the name if the name is not provided
        }));
      } catch (error) {
        console.error("Error fetching vector store files:", error);
      }
    },
    // Handle file upload
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.uploadedFile = file;
    },
    // Add file to vector store
    async addFileToVectorStore() {
      try {
        // Step 1: Upload the file to OpenAI's file storage
        const formData = new FormData();
        formData.append("file", this.uploadedFile);
        formData.append("purpose", "assistants");

        const uploadResponse = await fetch("https://api.openai.com/v1/files", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
          },
          body: formData,
        });
        const uploadedFile = await uploadResponse.json();

        // Step 2: Add the uploaded file to the vector store
        const addFileResponse = await fetch(`https://api.openai.com/v1/vector_stores/${this.vectorStoreId}/files`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2",
          },
          body: JSON.stringify({ file_id: uploadedFile.id }),
        });
        await addFileResponse.json();
        await this.loadVectorStoreFiles(); // Refresh the files
      } catch (error) {
        console.error("Error adding file to vector store:", error);
      }
    },
    // Remove file from vector store
    async removeFileFromVectorStore(fileId) {
      try {
        const response = await fetch(`https://api.openai.com/v1/vector_stores/${this.vectorStoreId}/files/${fileId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.apiKey}`,
            "OpenAI-Beta": "assistants=v2",
          },
        });
        await response.json();
        await this.loadVectorStoreFiles(); // Refresh the files
      } catch (error) {
        console.error("Error deleting file from vector store:", error);
      }
    },


    // Edit an assistant
    editAssistant(assistant) {
      this.newAssistant = assistant;
      this.isEditing = true;
      this.showCreateModal = true;

      // If the assistant has a file search tool, load its files
      if (assistant.tool_resources.file_search.vector_store_ids.length) {
        this.vectorStoreId = assistant.tool_resources.file_search.vector_store_ids[0];
        this.loadVectorStoreFiles();
        this.showFileManagement = true;
      }
      this.$emit('update', {
        "private": this.privateApp,
        "tool_type": 'openai_asst',
        "apiKey": this.apiKey,
        "name": this.name,
        "description": this.description,
        "assistant_id": this.newAssistant.assistant_id
      });
    },

    // Existing method to open the modal for creating a new assistant
    openCreateModal() {
      this.newAssistant = {
        name: "",
        instructions: "",
        model: "gpt-4",
        "tools": [{"type": "file_search"}],
      };
      this.showFileManagement = false;
      this.isEditing = false;
      this.showCreateModal = true;
    },
  },
  components: {
    MaterialTextarea,
    MaterialInput,
    MaterialSwitch,
    VueFinalModal,
  },
};
</script>

<style>

.assistants.active, .choose-expert .active {
  border: 2px solid #66bb6a !important;
}

.delete-border{
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.edit-border{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>