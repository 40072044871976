<template>
  <div class="documentation-link">
    <i
        class="material-icons info-icon"
        ref="popoverIcon"
        tabindex="0"
        role="button"
        :title="popoverTitle"
        @click="togglePopover"
    >
      help_outline
    </i>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import bootstrap from "bootstrap/dist/js/bootstrap";

export default {
  name: "DocumentationLink",
  props: {
    description: {
      type: String,
      required: true
    },
    docLink: {
      type: String,
      required: true
    },
    popoverTitle: {
      type: String,
      default: "Feature Documentation"
    }
  },
  setup(props) {
    const popoverIcon = ref(null);
    const popoverInstance = ref(null); // Store the popover instance

    const togglePopover = () => {
      if (popoverInstance.value) {
        popoverInstance.value.toggle(); // Toggle the popover visibility
      } else {
        popoverInstance.value = new bootstrap.Popover(popoverIcon.value, {
          trigger: 'manual', // Manual trigger
          html: true, // Allow HTML content
          content: createPopoverContent(), // Set the content to the description
          title: props.popoverTitle, // Set the title to the popover title
        });
        popoverInstance.value.show(); // Show the popover
      }
    };

    const createPopoverContent = () => {
      // Use backticks for template literals
      return `
        <div>
          <p>${props.description}</p>
          <a class="btn btn-primary w-100 mb-0" style="margin-top: 5px;" target="_blank" href="${props.docLink}">Open Documentation</a>
        </div>
      `;
    };

    const closePopover = (event) => {
      if (popoverIcon.value && !popoverIcon.value.contains(event.target)) {
        popoverInstance.value?.hide(); // Hide the popover if clicking outside
      }
    };

    onMounted(() => {
      document.addEventListener('click', closePopover); // Add event listener for clicks outside
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', closePopover); // Clean up listener
      popoverInstance.value?.dispose(); // Dispose of the popover instance
    });

    return {
      popoverIcon,
      togglePopover,
    };
  }
};
</script>

<style scoped>
/* Style for the icon to match Material Dashboard */
.info-icon {
  font-size: 24px;
  cursor: pointer;
  color: #83abd6; /* Adjust the color to fit your design */
}
</style>
