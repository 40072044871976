<template>

  <div class="loading-screen">
    <vue3-lottie ref="lottie"
                 :animationData="animationData"
                 height="60vh"
                 width="100%"
    />
    <p class="w-100">Loading...</p>
  </div>
</template>

<script>
import loadingChat from "@/assets/img/illustrations/loadingChat.json";

export default {
  name: "Loading",
  data() {
    return {
      animationData: loadingChat,
    };
  },
};
</script>

<style scoped>
.loading-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 2em;
}
</style>
