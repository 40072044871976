<template>
  <div class="welcome-message">
    <h3>Welcome to B-Bot!</h3>
    <p>Select a template to get started:</p>
    <ul>
      <li v-for="(template, index) in expertTemplates" class="mb-3" :key="index">
        <button class="btn mb-0 bg-gradient-primary btn-md w-100" @click="selectTemplate(template.attributes.template_text)">{{ template.attributes.template_text }}</button>
      </li>
    </ul>

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: 'WelcomeMessage',
  computed: {
    ...mapState(['selectedExpert']),
    expertTemplates() {
      // Check if the selectedExpert and its templates are defined
      if (this.selectedExpert && this.selectedExpert.attributes.templates.data.length > 0) {
        return this.selectedExpert.attributes.templates.data;
      }
      return []; // Return an empty array if no templates are available or no expert is selected
    }
  },
  methods: {
    ...mapMutations(['setSelectedTemplateText']),
    selectTemplate(templateText) {
      this.setSelectedTemplateText(templateText);
    },
  },
};
</script>

<style scoped>
.welcome-message {
  padding: 20px;
  text-align: center;
}

.welcome-message ul {
  list-style-type: none;
  padding: 0;
}
</style>
