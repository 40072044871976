<template>
  <VueFinalModal
      v-model="isModalOpen"
      class="flex justify-center items-center confirm-modal overflow-scroll"
      content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <template #header>
      <h3>Settings</h3>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
    </template>

    <template #default>
      <div class="card-header bg-gradient-primary d-flex justify-content-between shadow-primary card-header-top">
        <h5 class="modal-title text-white">Add File</h5>
      </div>
      <div class="file-input-container mt-3 mb-3 card col-3">
        <label for="file-upload" class="file-upload">
          <span><img class="w-100" src="@/assets/img/illustrations/folder-dynamic-gradient.png"> Datei laden</span>
        </label>
        <input type="file" id="file-upload" @change="handleFileUpload" />
      </div>
      <button @click="closeModal" class="close-btn btn">
        <i class="material-icons-round opacity-10 fs-5">close</i>
      </button>
      <button @click="closeModal" class="btn btn-success w-100">
        <i class="material-icons-round opacity-10 fs-5">save</i>
      </button>
    </template>

    <template #footer>
      <button @click="closeModal" class="close-btn">Close</button>
    </template>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    VueFinalModal,
  },
  props: {
    modelValue: Boolean,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapState(['selectedExpert','chatSettings', 'user']),
    isModalOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    localTemperature: {
      get() {
        return this.chatSettings.temperature;
      },
      set(value) {
        this.updateChatSettings({ setting: 'temperature', value });
      }
    },
    localTopP: {
      get() {
        return this.chatSettings.top_p;
      },
      set(value) {
        this.updateChatSettings({ setting: 'top_p', value });
      },
    },
    localPrivateSession: {
      get() {
        return this.chatSettings.private_session;
      },
      set(value) {
        this.updateChatSettings({ setting: 'private_session', value });
      }
    },
  },
  methods: {
    ...mapMutations(['setSelectedModel', 'updateChatSettings', 'setChatDocumentUrls']),
    closeModal() {
      this.isModalOpen = false;
    },
    updateTemperature() {
      this.$store.commit('updateChatSettings', { setting: 'temperature', value: this.localTemperature });
    },
    updateTopP() {
      this.$store.commit('updateChatSettings', { setting: 'top_p', value: this.localTopP });
    },
    updatePrivateSession() {
      this.$store.commit('updateChatSettings', { setting: 'temperature', value: this.localTemperature });
    },
    async handleFileUpload(event) {
      this.isLoading = true;

      const file = event.target.files[0];
      if (!file) {
        this.isLoading = false;
        alert('No file selected.');
        return;
      }

      const reader = new FileReader();

      // Using a closure to create a stable reference for the reader's onload
      reader.onload = async (event) => {
        const base64File = event.target.result.split(',')[1]; // Get base64 part

        try {
          // Upload the file to your backend and receive the pre-signed URL
          const response = await fetch('/.netlify/functions/uploadFile', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              file: base64File,
              fileName: file.name,
              userId: this.user.sub,  // Pass user ID or relevant user info in the body

            }),
          });

          const data = await response.json();
          if (response.ok) {
            alert('File uploaded successfully!');

            // Display pre-signed URL or use it further in your app

            // Optional: You can display the pre-signed URL in the modal
            this.downloadUrl = data.downloadUrl;
            this.setChatDocumentUrls([this.downloadUrl]);

          } else {
            alert(`Upload failed: ${data.message}`);
          }
        } catch (error) {
          alert('Error uploading file');
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      };

      reader.onerror = () => {
        this.isLoading = false;
        alert('Error reading file');
      };

      reader.readAsDataURL(file);
    }

  },
};
</script>

<style scoped>

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.file-input-container input[type="file"] {
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  cusror: pointer;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-controls button {
  margin: 0 10px;
}

input#file-upload[type="file"] {
  display: none;
}
.file-upload {
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
}

</style>
